import user from "../../mixins/user";

export default {
  mounted() {
    this.renderMenu()
  },
  mixins: [user],
  methods: {
    renderMenu() {
      this.listData = [
        {
          permission: !this.permissions.crm_user,
          icon: "mdi-home",
          navigate_name: "cabinet",
          title: this.$t("menu.home")
        },
        {
          permission:
            (this.permissions.client || this.permissions.crm_user) && this.permissions.access_crm && this.getUser.crm_days_left,
          icon: "mdi-view-dashboard",
          navigate_name: "crm",
          title: this.$t("menu.crm"),
        },
        {
          permission: this.permissions.call_center && this.getUser.crm_days_left,
          navigate_name: "call-center",
          icon: "mdi-phone-plus",
          title: this.$t("menu.call_center")
        },
        {
          permission: this.permissions.can_view_users_page,
          icon: "mdi-view-dashboard",
          value: this.usersSelector,
          activator_data: {
            icon: "mdi-account",
            title: this.$t("menu.users")
          },
          nested_data: [
            {
              permission: this.permissions.can_see_crm_users_list,
              icon: "mdi-tooltip-account",
              navigate_name: "crm-users",
              title: this.$t("menu.crm")
            },
            {
              permission: true,
              icon: "mdi-clipboard-account",
              navigate_name: "clients",
              title: this.$t("menu.clients")
            },
            {
              permission: true,
              icon: "mdi-account-hard-hat",
              navigate_name: "employees",
              title: this.$t("menu.employees")
            },
            {
              permission: true,
              icon: "mdi-account-group",
              navigate_name: "managers",
              title: this.$t("menu.managers")
            },
            {
              permission: this.permissions.can_see_managers_and_higher_list,
              icon: "mdi-account-star",
              navigate_name: "admins",
              title: this.$t("menu.admins")
            },
            {
              permission: this.permissions.can_see_managers_and_higher_list,
              icon: "mdi-account-cowboy-hat",
              navigate_name: "owners",
              title: this.$t("menu.owners")
            }
          ]
        },
        {
          permission: true,
          icon: "mdi-map-marker",
          navigate_name: "addresses",
          title: this.$t("menu.addresses")
        },
        {
          permission: this.permissions.client && this.permissions.access_crm && this.getUser.crm_days_left,
          icon: "mdi-cash-multiple",
          navigate_name: "prices",
          title: this.$t("menu.prices")
        },
        {
          permission: this.permissions.can_catalog,
          icon: "mdi-package-variant",
          navigate_name: "catalog",
          title: this.$t("menu.catalog")
        },
        {
          permission: this.permissions.clients_products,
          icon: "mdi-package",
          navigate_name: "products",
          title: this.$t("menu.NS_deport")
        },
        {
          permission: this.permissions.can_use_depots,
          icon: "mdi-view-dashboard",
          class: "listGroupMenu",
          color: "var(--v-green-base)",
          active_class: "active-green--text",
          value: this.depotsSelector,
          navigate_name: "nested-data",
          activator_data: {
            icon: "mdi-package",
            title: this.$t("menu.depots")
          },
          nested_data: [
            {
              permission: true,
              icon: "mdi-tshirt-crew",
              navigate_name: "products",
              title: this.$t("menu.depots_products")
            },
            {
              permission: this.permissions.can_view_cells_page,
              icon: "mdi-table-merge-cells",
              navigate_name: "cells",
              title: this.$t("menu.depots_cells")
            },
            {
              permission: this.permissions.can_view_sectors_page,
              icon: "mdi-view-dashboard",
              navigate_name: "sectors",
              title: this.$t("menu.depots_sectors")
            },
            {
              permission: this.permissions.can_view_depot_page,
              icon: "mdi-warehouse",
              navigate_name: "depots",
              title: this.$t("menu.depots")
            },
            {
              permission: this.permissions.can_view_ns_codes_page,
              icon: "mdi-barcode-scan",
              navigate_name: "ns-codes",
              title: this.$t("menu.ns_codes")
            }
          ]
        },
        {
          permission: !this.permissions.crm_user === true,
          navigate_name: "supply",
          title: this.$t("menu.supply")
        },
        {
          permission: this.permissions.can_view_move_list_page,
          navigate_name: "move-list",
          title: this.$t("menu.move_list")
        },
        // {
        //   permission: this.permissions.can_view_transactions_page,
        //   icon: "mdi-credit-card-multiple-outline",
        //   navigate_name: "transactions",
        //   title: this.$t("menu.transactions")
        // },
        // {
        //   permission: this.permissions.can_view_plans_list_page,
        //   navigate_name: "plans-list",
        //   title: this.$t("menu.plans_list")
        // },
        {
          permission: this.permissions.can_view_join_request,
          navigate_name: "contacts",
          title: this.$t("menu.contacts"),
          icon: "mdi-account-multiple-plus",
        },
        {
          permission: true,
          icon: "mdi-folder-multiple",
          navigate_name: "documents",
          navigate_params: {uuid: "all"},
          title: this.$t("menu.documents"),
        },
      ];
    }
  }
};
