<template>
  <VDialog v-model="visibility" width="500px"  :transition="$dialogTransition">
    <VCard :loading="loading">
      <VCardTitle>
       {{$t('table.description')}}
      </VCardTitle>
      <VDivider class="mx-5 mb-3" />
      <VCardText>
        <VRow>
          <VCol>
            <v-textarea
              outlined
              :label="$t('form.description')"
              dense
              hide-details="auto"
              v-model="item.description"
              rows="3"
              counter="250"
              maxLength="250"
              color="success"
            >
            </v-textarea>
          </VCol>
        </VRow>
      </VCardText>
      <VDivider class="mx-5" />
      <VCardActions>
        <VCol xl="6" md="6" sm="6" xm="12">
          <VBtn block dark class="br-10 text-transform-none" @click="onCancel">
            {{ $t("btn.cancel") }}
          </VBtn>
        </VCol>
        <VCol>
          <VBtn
            block
            class="br-10 success-bg white--text text-transform-none"
            :loading="loading"
            :disabled="btnDisabled"
            @click="onUpdate()"
          >
            {{ $t("btn.update") }}
          </VBtn>
        </VCol>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import language from "@/mixins/language";
import notifications from "@/mixins/notifications";
import depotService from "@/services/request/depot/depotService";

export default {
  props: {
    visible: {
      require: true
    },
    product: {
      require: true
    }
  },
  data: () => ({
    loading: false,
    btnDisabled: false,
    item: {
      description: ''
    }
  }),
  methods: {
    async onUpdate() {
      this.$emit("onUpdate", this.item);
      this.setSuccessNotification(this.$t('nsCodes.successfully_updated'));
      this.$emit("close");
    },
    onCancel() {
      this.$emit("close");
    },
  },
  mounted() {
    this.item = {...this.product}
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
  },
  mixins: [notifications, language]
};
</script>

<style scope lang="scss">
textarea {
  cursor: auto;
}
textarea::-webkit-scrollbar{
  width: 6px;
}
</style>
