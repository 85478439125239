<template>
  <VCard width="100px" class="pa-3">
    <v-progress-linear
      v-if="loading"
      absolute
      top
      indeterminate
      color="success"
    ></v-progress-linear>
    <v-row align="center" justify="center">
      <VCol cols="12" class="text-center py-1 px-1">
        <VBtn color="blue" class="text-white" @click="openLink('html')" block
          ><VIcon color="white" small>mdi-language-html5</VIcon>html</VBtn
        >
      </VCol>
      <VCol cols="12" class="text-center py-1 px-1">
        <VBtn color="warning" @click="openLink('pdf')" block
          ><VIcon color="white" small>mdi-file-pdf-box</VIcon>pdf</VBtn
        >
      </VCol>
      <VCol cols="12" class="text-center py-1 px-1">
        <VBtn color="blue-grey" class="text-white" @click="openLink('text')" block
          ><VIcon color="white" small>mdi-text-box-outline</VIcon>text</VBtn
        >
      </VCol>
      <VCol cols="12" class="text-center py-1 px-1">
        <VBtn class="text-white" color="deep-purple darken-2" @click="openLink('png')" block
          ><VIcon color="white" small>mdi-image-outline</VIcon>png</VBtn
        >
      </VCol>
    </v-row>
  </VCard>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import notifications from "@/mixins/notifications";
import language from "@/mixins/language";

export default {
  data: () => ({
    loading: false,
  }),
  mixins: [notifications, language],
  props: {
    item: {
      required: true
    }
  },
  methods: {
    openLink(type) {
      window.open(
        `https://api.checkbox.ua/api/v1/receipts/${this.item.uuid_receipt}/${type}`,
        "_blank"
      );
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"])
  }
};
</script>
