<template>
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M19 11V9a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2"></path><path d="M13 13l9 3l-4 2l-2 4l-3-9"></path><path d="M3 3v.01"></path><path d="M7 3v.01"></path><path d="M11 3v.01"></path><path d="M15 3v.01"></path><path d="M3 7v.01"></path><path d="M3 11v.01"></path><path d="M3 15v.01"></path></g></svg>

</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'Drag',
};
</script>

<style scoped>

</style>
