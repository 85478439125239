<template>
  <div class="ns-badge__wrapper">
    <div
      class="ns-badge__inner"
      :class="[
        {
          border: nsBorder === true,
          'no-content': !nsContent,
          sm: nsSize === 'sm',
          xs: nsSize === 'xs',
          'd-none': !showBadge
        },
        nsColor.indexOf('#') ? nsColor : ''
      ]"
      :style="!nsColor.indexOf('#') ? `background:${nsColor}` : ''"
    >
      {{ nsContent }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ns-badge",
  props: {
    showBadge: {
      require: false,
      default: true
    },
    nsContent: {
      require: false,
      default: null
    },
    nsColor: {
      require: false,
      default: "succes"
    },
    nsBorder: {
      require: false,
      default: false
    },
    nsSize: {
      require: false
    }
  }
};
</script>

<style lang="scss" scoped>
.ns-badge {
  &__wrapper {
    width: 100%;
    position: relative;
  }
  &__inner {
    padding: 0 4px;
    background: #4caf50;
    border-radius: 30px;
    position: absolute;
    top: -3px;
    right: -3px;
    font-size: 10px;
    z-index: 1;
    color: #fff;
    font-weight: 600;
    &.purple {
      color: #fff;
      background: #9c27b0;
    }
    &.error {
      color: #fff;
      background: #f44336;
    }
    &.warning {
      color: #fff;
      background: #ff9800;
    }
    &.info {
      color: #fff;
      background: #03a9f4;
    }
    &.success {
      color: #fff;
      background: #4caf50;
    }
    &.border {
      border: 1px solid #fff !important;
    }
    &.no-content {
      width: 10px;
      height: 10px;
    }
    &.sm {
      padding: 2px 6px;
      right: -7px;
    }
    &.xs {
      padding: 1px 5px;
      right: -17px;
    }
  }
}
</style>
