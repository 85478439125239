<template>
  <div class="category-chips">
    <v-chip-group
      mandatory
      :active-class="!onChangeByClick ? '' : 'category-chips__active'"
      show-arrows
      @change="onChange"
      v-model="getActiveChip"
    >
      <v-chip
        v-if="showAll"
        small
        outlined
        class="category-chips"
        :disabled="loadingChips"
        :value="$t('btn.all')"
      >
        {{ $t("btn.all") }}
        <span v-if="sum_options" class="price pl-1">
          {{ `${sum_options} ${$t("table.piece")}` }}
        </span>
      </v-chip>
      <v-chip
        v-for="item in items"
        :key="item.name"
        small
        :value="item.name"
        :disabled="loadingChips"
        outlined
        class="category-chips"
      >
        {{ item.name }}
        <span v-if="showCount" class="price pl-1">
          {{ `${item.amount} ${$t("table.piece")}` }}
        </span>
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
export default {
  data: () => ({
    activeChip: "",
    getActiveChip: ""
  }),
  props: {
    items: {
      require: true
    },
    loadingChips: {
      require: false
    },
    resetChip: {
      require: false
    },
    showCount: {
      require: false,
      default: false
    },
    showAll: {
      require: false,
      default: true
    },
    sum_options: {
      require: false,
      type: Number
    },
    onChangeByClick: {
      require: false,
      default: true
    }
  },
  mounted() {
    this.setActiveChip();
  },
  watch: {
    getActiveChip(e) {
      //
    },
    resetChip: {
      deep: true,
      handler(e) {
        this.setActiveChip();

      }
    }
  },
  methods: {
    setActiveChip() {
      if (this.showAll) {
        this.getActiveChip = this.$t("btn.all");
        this.activeChip = this.$t("btn.all");
      }
    },
    onChange(e) {
      if (this.onChangeByClick) {
        this.activeChip = e;
        this.$emit("onClickChipTab", e);
      }
    }
  }
};
</script>

<style lang="scss">
.category-chips {
  //   .v-slide-group__wrapper {
  //   overflow-x: auto !important;;
  //   -ms-overflow-style: none;
  //   scrollbar-width: none;
  // }
  // .v-slide-group__wrapper::-webkit-scrollbar {
  //   display: none;
  //   width: 0px;
  //   background: transparent;
  // }
  //   .v-slide-group__next, .v-slide-group__prev{
  //     min-width: unset!important
  //   }
  &__active {
    &.v-chip.v-chip--outlined.v-chip.v-chip {
      background: #A9A9A9 !important;
      color: #ffff;
      font-weight: 600;
    }
  }
  &__all {
    &.v-chip.v-chip--outlined.v-chip.v-chip {
      background: linear-gradient(84.3deg, #eeaeca -28.14%, #94bbe9 169.26%);
      font-weight: 600;
      color: #ffff;
    }
  }
  &.v-chip.v-chip--outlined.v-chip.v-chip {
    box-shadow: inset 0px 0px 11px 1px rgba(0, 0, 0, 0.03);
  }
}
</style>
