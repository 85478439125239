<template>
  <v-dialog v-model="visibility" scrollable content-class="cross__dialog" max-width="1100px">
    <v-card :loading="loading" :disabled="loading">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="$emit('close')">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <v-card-title>
        <span>Надіслати напряму</span>
      </v-card-title>
      <v-card-text>
        <ns-code-table :options_available="options_available" :uuid_delivery="uuid_delivery" :uuid_option="uuid_option" :uuid_prod="item.uuid_product" v-if="mainItem && !loading" :mainItem="mainItem" type="direct"/>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import nsCodeTable from "@/views/depots/components/nsCodeTable.vue";
import depotService from "@/services/request/depot/depotService";

export default {
  components: { nsCodeTable },
  data: () => ({
    loading: true,
    mainItem: []
  }),
  props: {
    uuid_delivery:{
        require: true,
      type: String,
      default: ""
    },
    item: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: false
    },
    uuid_option: {
      require: true,
      type: String,
      default: ""
    },
    options_available: {
      require: true,
    }
  },
  async mounted() {
    try {
      this.mainItem = await depotService.getProduct(this.item.uuid_product);
      if (!this.mainItem) {
        // eslint-disable-next-line
        throw "Item Not Found";
      }

      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.log(e);
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>

<style></style>
