import * as mutationTypes from './types/mutations';
import { DEFAULT_STORAGE } from "@/mixins/constants";

export default {
  [mutationTypes.SET_TOTAL_OUT]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("ns-data") !== null) {
      data = JSON.parse(localStorage.getItem("ns-data"));
    }
    data.totalOut = value;
    localStorage.setItem("ns-data", JSON.stringify(data));

    state.totalOut = value;
  },
  [mutationTypes.SET_TOTAL_IN]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("ns-data") !== null) {
      data = JSON.parse(localStorage.getItem("ns-data"));
    }
    data.totalIn = value;
    localStorage.setItem("ns-data", JSON.stringify(data));

    state.totalIn = value;
  },
  [mutationTypes.SET_TOTAL_RETURN]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("ns-data") !== null) {
      data = JSON.parse(localStorage.getItem("ns-data"));
    }
    data.totalReturn = value;
    localStorage.setItem("ns-data", JSON.stringify(data));

    state.totalReturn = value;
  },
};
