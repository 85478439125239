<template>
  <v-dialog
    v-model="visibility"
    max-width="580"
    content-class="cross__dialog"
    :persistent="changed"
    :transition="$dialogTransition"
    @click:outside="showConfirmDialog"
    ><v-card>
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <v-btn fab small elevation="0" @click="showConfirmDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <v-card-title>
        {{ $t("cabinet.add_balance") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-simple-table class="my-table" style="max-height: 250px;" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th><v-icon size="18">mdi-cogs</v-icon></th>
                    <th>{{ $t("table.name") }}</th>
                    <th class="text-center">{{ $t("table.amount") }}</th>
                    <th>{{ $t("table.price") }}</th>
                    <th>{{ $t("table.sum") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <v-checkbox
                        color="success"
                        hide-details
                        v-model="isDeliveries"
                        class="pt-0"
                      ></v-checkbox>
                    </td>
                    <td class="font-weight-medium">{{ $t("cabinet.deliveries") }}</td>
                    <td class="py-1">
                      <div
                        class="d-flex justify-center"
                        :style="!isDeliveries ? 'pointer-events: none' : ''"
                      >
                        <ns-counter
                          v-model="inputForm.deliveries"
                          :min="0"
                          :max="999999"
                          :maxlength="6"
                        >
                        </ns-counter>
                      </div>
                    </td>
                    <td v-if="permissions.balance_manager">
                      {{ balance.price_delivery }}{{ $t("paymentPlan.uah") }}
                    </td>
                    <td v-else>{{ userPlanInfo.price_delivery }}{{ $t("paymentPlan.uah") }}</td>
                    <td v-if="permissions.balance_manager">
                      {{ Number(balance.price_delivery) * inputForm.deliveries
                      }}{{ $t("paymentPlan.uah") }}
                    </td>
                    <td v-else>
                      {{ Number(userPlanInfo.price_delivery) * inputForm.deliveries
                      }}{{ $t("paymentPlan.uah") }}
                    </td>
                  </tr>
                  <tr v-if="permissions.balance_client">
                    <td class="text-center">
                      <v-checkbox
                        color="success"
                        hide-details
                        v-model="isCrm"
                        class="pt-0"
                      ></v-checkbox>
                    </td>
                    <td class="font-weight-medium">{{ $t("cabinet.crm_days") }}</td>
                    <td class="py-1 ">
                      <div
                        class="d-flex justify-center"
                        :style="!isCrm ? 'pointer-events: none' : ''"
                      >
                        <ns-counter
                          v-model="inputForm.crmDays"
                          :min="0"
                          :max="999999"
                          :maxlength="6"
                        >
                        </ns-counter>
                      </div>
                    </td>
                    <td>{{ userPlanInfo.price_day_crm }}{{ $t("paymentPlan.uah") }}</td>
                    <td>
                      {{ Number(userPlanInfo.price_day_crm) * inputForm.crmDays
                      }}{{ $t("paymentPlan.uah") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col cols="12" class="px-3 py-0 d-flex justify-end"
            ><span
              class="font-weight-medium"
              style="font-size: 13px;"
              :class="!isEnoughBalance ? 'text-error' : ''"
              >{{ $t("cabinet.total") }}: {{ getTotalPrice }}{{ $t("paymentPlan.uah") }}</span
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          class="success-bg text-white text-transform-none br-10"
          :disabled="
            !isEnoughBalance ||
              (inputForm.deliveries == 0 && inputForm.crmDays == 0) ||
              (!isCrm && !isDeliveries)
          "
          :loading="loading"
          @click="balanceConfirmDialog = true"
          >{{ $t("cabinet.add_balance") }}</v-btn
        >
      </v-card-actions></v-card
    >
    <ConfirmLeftDialog
      :visible="visibleConfirm"
      @close="visibleConfirm = false"
      @delete="onCancel"
    />
    <confirm-dialog
      :visible="balanceConfirmDialog"
      @close="balanceConfirmDialog = false"
      @onConfirm="onClick"
      @onCancel="balanceConfirmDialog = false"
      :typeAlert="'success'"
    >
      <template v-slot:header>{{ $t("cabinet.add") }}</template>
      <template v-slot:description>
        {{ $t("cabinet.confirm_add") }} <br />
        <v-simple-table class="my-table balance-table" style="max-height: 250px;" dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th>{{ $t("table.name") }}</th>
                <th class="text-center">{{ $t("table.amount") }}</th>
                <th>{{ $t("table.price") }}</th>
                <th>{{ $t("table.sum") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="isDeliveries">
                <td class="font-weight-medium">
                  {{ $t("cabinet.deliveries") }}
                </td>
                <td class="text-center">
                  {{ inputForm.deliveries }}
                </td>
                <td>{{ userPlanInfo.price_delivery }}{{ $t("paymentPlan.uah") }}</td>
                <td>
                  {{ Number(userPlanInfo.price_delivery) * inputForm.deliveries
                  }}{{ $t("paymentPlan.uah") }}
                </td>
              </tr>
              <tr v-if="isCrm">
                <td class="font-weight-medium">{{ $t("cabinet.crm_days") }}</td>
                <td class="text-center">
                  {{ inputForm.crmDays }}
                </td>
                <td>{{ userPlanInfo.price_day_crm }}{{ $t("paymentPlan.uah") }}</td>
                <td>
                  {{ Number(userPlanInfo.price_day_crm) * inputForm.crmDays
                  }}{{ $t("paymentPlan.uah") }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row justify="end">
          <v-col cols="12" class="px-3 d-flex justify-end"
            ><span
              class="font-weight-medium pt-2"
              style="font-size: 13px;"
              :class="!isEnoughBalance ? 'text-error' : ''"
              >{{ $t("cabinet.total") }}: {{ getTotalPrice }}{{ $t("paymentPlan.uah") }}</span
            >
          </v-col>
        </v-row>
      </template></confirm-dialog
    ></v-dialog
  >
</template>

<script>
import { mapActions } from "vuex";
import _ from "lodash";
import ConfirmLeftDialog from "@/components/dialog/ConfirmLeftDialog.vue";
import user from "@/mixins/user";
import paymentPlansService from "@/services/request/payment-plans/paymentPlansService";
import * as actionTypes from "@/store/modules/auth/types/actions";
import crmService from "@/services/request/crm/crmService";
import notifications from "@/mixins/notifications";
import EventBus from "@/events/EventBus";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";

export default {
  mixins: [user, notifications],
  data: () => ({
    loading: false,
    visibleConfirm: false,
    balanceConfirmDialog: false,
    inputForm: {
      deliveries: 100,
      crmDays: 30
    },
    isDeliveries: true,
    isCrm: true,
    copyForm: {},
    userPlanInfo: {},
    changed: false,
    currentBalance: 0
  }),
  props: {
    visible: {
      require: true
    },
    balance: {
      require: false
    }
  },
  components: {
    ConfirmLeftDialog,
    ConfirmDialog
  },
  async mounted() {
    if (this.permissions.balance_manager) {
      this.inputForm.crmDays = 0;
      this.currentBalance = this.balance.balance;
      this.userPlanInfo.price_delivery = this.balance.price_delivery;
      this.isCrm = false;
    } else if (this.permissions.balance_client) {
      await this.getPaymentPlan();
      this.currentBalance = this.balance.balance;
    }
    this.copyForm = { ...this.inputForm };
  },
  methods: {
    ...mapActions("auth", {
      fetchUser: actionTypes.FETCH_USER_DATA
    }),
    onCancel() {
      this.$emit("close");
    },
    showConfirmDialog() {
      this.changed ? (this.visibleConfirm = true) : this.onCancel();
    },
    async onClick() {
      this.loading = true;
      try {
        const formData = new FormData();
        let services = [];
        if (this.permissions.balance_client) {
          services = [
            { type: "USER_DELIVERY_AMOUNT", quantity: this.inputForm.deliveries },
            { type: "USER_CRM_DAYS", quantity: this.inputForm.crmDays }
          ];
        } else if (this.permissions.balance_manager) {
          services = [{ type: "DEPORT_DELIVERY_AMOUNT", quantity: this.inputForm.deliveries }];
        }
        let counter = 0;
        services.forEach(service => {
          if (service.quantity > 0) {
            if (this.isCrm && service.type == "USER_CRM_DAYS") {
              formData.append(`services[${counter}][type]`, service.type);
              formData.append(`services[${counter}][quantity]`, service.quantity);
              counter++;
            }
            if (
              this.isDeliveries &&
              (service.type == "USER_DELIVERY_AMOUNT" || service.type == "DEPORT_DELIVERY_AMOUNT")
            ) {
              formData.append(`services[${counter}][type]`, service.type);
              formData.append(`services[${counter}][quantity]`, service.quantity);
              counter++;
            }
          }
        });
        await crmService.buyBalance(formData);
        this.setSuccessNotification(this.$t("cabinet.balance_success"));
        EventBus.$emit("balance-added");
        this.visibility = false;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async getPaymentPlan() {
      try {
        if (this.getUser.uuid_user_plan) {
          this.loading = true;
          const planItem = await paymentPlansService.usersPlansInfo(
            this.getUserProperty("uuid_user_plan")
          );
          this.userPlanInfo = planItem;
          this.loading = false;
        }
      } catch (e) {
        this.setErrorNotification(this.$t("paymentPlan.withoutPaymentPlan"));
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    getTotalPrice() {
      const crm = this.isCrm ? Number(this.userPlanInfo.price_day_crm) * this.inputForm.crmDays : 0;
      const deliveries = this.isDeliveries
        ? Number(this.userPlanInfo.price_delivery) * this.inputForm.deliveries
        : 0;
      return crm + deliveries;
    },
    isEnoughBalance() {
      return this.getTotalPrice <= Number(this.currentBalance);
    }
  },
  watch: {
    inputForm: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyForm, this.inputForm);
      }
    }
  }
};
</script>

<style scoped>
.balance-table thead tr th::after {
  border: none !important;
}
.balance-table tbody td,
.balance-table tbody th {
  border: none !important;
}
</style>
