import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        green: '#4FAE3B',
        gray: '#3D3D3D',
        error: '#F4511E',
        green_light: '#cef2c8',
      },
    },
    options: {
      customProperties: true,
    },
  },
});
