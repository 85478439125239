import auth from "./auth.json";
import error from "./error.json";
import menu from "./menu.json";
import orderDialog from "./orderDialog.json";
import paymentPlan from "./payment-plan.json";
import moveList from "./move-list.json";
import cell from "./cell.json";
import cabinet from "./cabinet.json";
import crm from "./crm.json";
import confirmDialog from "./confirm-dialog.json";
import addresses from "./addresses.json";
import table from "./table.json";
import users from "./users.json";
import supply from "./supply.json";
import supplyStatuses from "./supply-statuses.json";
import profile from "./profile.json";
import catalog from "./catalog.json";
import sectors from "./sectors.json";
import storages from "./storages.json";
import nestedData from "./nested-data.json";
import products from "./products.json";
import chat from "./chat.json";
import history from "./history.json";
import chart from "./chart.json";
import printDialog from "./print-dialog.json";
import market from "./market.json";
import callCenter from "./call-center.json";
import sms from "./sms.json";
import landing from "./landing.json";
import documentation from './documentation.json'
import payType from './pay-type.json'
import theme from './theme.json'
import nsCodes from './ns-codes.json'
import nsSms from './ns-sms.json'

function notEngTxt(constant) {
  return `The field "${constant}" must be Cyrillic!`;
}
function isRequired(constant) {
  return `Field "${constant}" is mandatory!`;
}

export default {
  auth: { ...auth },
  error: { ...error },
  menu: { ...menu },
  cell: { ...cell },
  crm: { ...crm },
  cabinet: { ...cabinet },
  orderDialog: { ...orderDialog },
  theme: { ...theme },
  paymentPlan: { ...paymentPlan },
  moveList: { ...moveList },
  addresses: { ...addresses },
  table: { ...table },
  users: { ...users },
  confirmDialog: { ...confirmDialog },
  supply: { ...supply },
  supplyStatuses: { ...supplyStatuses },
  sectors: { ...sectors },
  profile: { ...profile },
  catalog: { ...catalog },
  storages: { ...storages },
  nestedData: { ...nestedData },
  products: { ...products },
  chat: { ...chat },
  history: { ...history },
  chart: { ...chart },
  printDialog: { ...printDialog },
  market: { ...market },
  callCenter: { ...callCenter },
  nsCodes: { ...nsCodes },
  nsSms: { ...nsSms },
  sms: { ...sms },
  landing: { ...landing },
  documentation: { ...documentation },
  payType: {...payType},
  mainTitle: "Your Online Warehouse",
  loadMore: "Load more",
  translations:"TRANSLATIONS",
  form: {
    discount: "Promotion",
    rating: "Rating",
    email: "E-mail",
    password: "Password",
    name: "Name",
    surname: "Surname",
    phone_number: "Phone number",
    city: "City",
    status: "Status",
    time_last_contact: "Date last contact",
    time_created: "Date Created",
    description: "Description",
    title: "Title",
    storage: "Warehouse",
    company: "Company",
    limit: "Credit",
    balance: "Balance",
    deliveries_negative_limit: "Deliveries credit",
    deliveries_balance: "Balance of deliveries",
    add_to_balance: "Add to balance",
    default_deliveries_balance: "Default balance of deliveries",
    default_deliveries_negative_limit: "Default deliveries credit",
    price_delivery: "Delivery price",
    price_ns_code: "Price per NS Code",
    
    crm_user: "CRM user",
    end_crm_subs: "CRM Subscription End",
    more: "More details",
    addComment:"Add a comment",
    department:"Department",
    add:'Add',
    adding:"Adding",
    tariff_data:"Tariff data",
    additional_features:"Additional features",
    edit:"Edit",
    crm_end_date:"CRM subscription end",
    payment_plan:"Tariff plan",
    not_selected:"Not selected",
    photo:'Photo',
    search:"Search",
    client:"Client",
    ttn:"TTN",
    comment:"Comment",
    counterparty:"Counterparty",
    sender_name:"Sender Name",
    sender_surname:"Surname of the sender",
    sender_middlename:"Patronymic of the sender",
    phone_sender:"Sender number",
    phone_receiver:"Recipient number",
    name_receiver:"Recipient's name",
    surname_receiver:"Surname of receiver",
    middlename_receiver:"Patronymic of receiver",
    city_receiver:"Recipient city",
    payment_made:"Payment made",
    price:"Price",
    weight:"Weight",
    shipping_cost:"Shipping cost",
    choose:"Choose",
    sector:"Sector",
    cell:"Cell",
    top_sell : "Sell hit",
    date:"Date",
    counterparty_sender:"Sender of the counterparty",
    msg:"Message",
    not_specified: "Not specified",
    forms:"Forms",
    control: "Management",
    total_duration: "Total duration",
    start_run_time: "Start time",
    end_run_time: "End time",
    changed_time: "Changed time",
    created_time: "Created time",
    modules: "Modules",
    send_on_create: "Send on creation",
    process_status: "Status ID",
    key: "API Key",
    PayerType:"Payer type",
    PaymentMethod:"Payment method",
    Sender:"Sender",
    Recipient:"Recipient",
    ThirdPerson:"Third person",
    Cash:"Cash",
    NonCash:"Cashless",
    value: "Value",
    heading: "Heading",
    message: "Message",
    errors: {
      EmailRequired: isRequired("E-mail"),
      PasswordRequired: isRequired("Password"),
      NameRequired: isRequired("My Name"),
      SurnameRequired:isRequired("Surname"),
      MiddlenameRequired:isRequired("Patronymic"),
      PhoneNumberRequired: isRequired("Phone Number"),
      TitleRequierd: isRequired("Title"),
      RegionRequired:isRequired("Region"),
      CityRequired:isRequired("City"),
      DepartmentRequired:isRequired("Department"),
      StreetRequired:isRequired("Street"),
      HouseRequired:isRequired("House"),
      StorageRequired:isRequired('Storage'),
      ItemRequired:isRequired('Item'),
      WhereRequired:isRequired('Where'),
      StatusRequired:isRequired('Status'),
      AmountRequired:isRequired('Amount'),
      CurrentPassRequired:isRequired('CurrentPassword'),
      NewPassRequired:isRequired('New password'),
      ConfirmPassRequired:isRequired('Confirm Password'),
      CounterpartySenderRequired:isRequired('Counterparty Sender'),
      CounterpartyRequired:isRequired('Counterparty'),
      SectorRequired:isRequired('Sector'),
      NsCodeRequired:isRequired('Ns code'),
      AddressesRequired:isRequired('Address'),
      DescRequired:isRequired('Description'),
      PriceRequired:isRequired('Price'),
      paymentPlanHoursPriceRequired:isRequired('Price per h/m³'),
      paymentPlansDeliveryPriceRequired:isRequired('Delivery Price'),
      paymentPlansCrmPriceRequired:isRequired('Price per CRM/day'),
      ReasonDescRequired:isRequired('Reason description'),
      CashOnDeliveryRequired: isRequired('Payment made'),
      NPKeyRequired: isRequired('NP Api Key'),
      AddressRequired: isRequired('Address'),
      CargoDescriptionRequired: isRequired('Shipment Description'),
      UtmSourceRequired: isRequired('utm_Source'),
      rangeFromRequired: isRequired('Range from'),
      rangeToRequired: isRequired('Range to'),
      productRequired: isRequired('Product'),
      skuRequiered: isRequired("Sku"),
      skuRequire: "Sku must be more than '0'",
      ChoseDeport: "Choose Depot",
      EmailMustBeValid: "E-mail must be valid!",
      EnterTTN:"Enter TTN",
      EnterApiKey:"Add api key in settings",
      NonExistentTtn:'A non-existent TTN was entered',
      PasswordMustBeLike:
        "The password must contain upper and lowercase Latin letters, numbers and symbols, at least 8 characters!",
      PhoneNumberMustBeLike: "The phone number must be like +38 (999) 99-99-999",
      AtLeastOne:"must be at least 1!",
      PasswordsDontMatch:"Passwords do not match",
      NsCodeValidation:"NS code must start with 88 and consist of 16 digits!",
      inputDeliveryRequired: ("Fill all required details of the recipient"),
      photoMaxSizeAvatar: "The photo must be less than 2 МБ!",
      photoMaxSizeProduct: "The photo must be less than 8 MB!",

    },
    hint: {
      NameNoEngText: notEngTxt("Name"),
      SurnameNoEngText: notEngTxt("Surname"),
      MiddlenameNoEngText: notEngTxt("Patronymic"),
      CommentNoEngText: notEngTxt("Comment"),
    }
  },
  generate_ttn: {
    generate_ttn: "Generate TTN",
    question_generate_ttn: "Do you really want to generate TTN?",
    recipient: "Recipient",
    description_of_the_deliveries: "Description of the deliveries",
    appendix_to_the_description: "Appendix to the description",
    additional_information: "Additional Information"
  },
  btn: {
    create: "Create",
    cancel: "Cancel",
    update: "Update",
    back:"Back",
    addOrder:"Create order",
    all:"All",
    delete:"Delete",
    close:"Close",
    print:"Print",
    confirm:"Confirm",
    generate:"Generate",
    send:"Send",
    clear:"Clear",
    create_apply:"Create request",
    send_order: "Send order",
    create_order: "Create order",
    log_out:"Log out",
    add:"Add",
    adding:"Adding",
    save:"Save",
    increaseDimenstion:"Increase Dimenstion",
    decreaseDimenstion:"Reduce Dimenstion",
    order:'Order',
    apply_filters:"Apply Filters",
    ok:"Okey",
    needs_a_call_center:"Needs a call center"
  },
  offline: {
    title: 'No Internet Connection',
    subTitle: 'Check your Internet connection.'
  }
};
