import * as mutationTypes from './types/mutations';
import { userNormalizer } from '../../../services/normalizer/userNormalizer';

export default {
  [mutationTypes.SET_USER_DATA]: (state, userData) => {
    state.user = userNormalizer(userData);
    localStorage.setItem('userData', JSON.stringify(userNormalizer(userData)));
  },
  [mutationTypes.LOG_OUT]: (state) => {
    state.user = null;
    localStorage.setItem('userData', null);
  },
  [mutationTypes.SET_MENU_POSITION]: (state, status) => {
    state.menuPosition = status;
    localStorage.setItem('menuPosition', status ? 1 : 0);
  },
  [mutationTypes.SET_SHOW_500_ERROR_DIALOG]: (state,status) => {
    state.show500ErrorDialog = status;
    localStorage.setItem('show500ErrorDialog', status);
  },
  [mutationTypes.CHANGE_USER_DATA_BY_KEY]: (state,{key,value}) => {
    state.user[key] = value
    localStorage.setItem('userData', JSON.stringify(userNormalizer(state.user)));

  },
};
