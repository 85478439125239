<template>
  <VDialog
    v-model="visibility"
    max-width="600px"
     content-class="cross__dialog"
     scrollable
     :transition="$dialogTransition"

  >
    <VCard class="d-flex justify-center pa-3">
      <div class="cross__inner">
        <VBtn fab small elevation="0" @click="closeDialog">
          <VIcon>mdi-close</VIcon>
        </VBtn>
      </div>
      <img
        v-if="!imgpath"
        :src="require('@/assets/images/supply-item.png')"
        alt=""
        class="mb-0 pb-0 w-100 h-100 object-fit-contain cursor-pointer"
      />

      <img
        v-else
        :src="imgpath"
        v-show="isImgLoaded"
        @load="showImg"
        class="mb-0 pb-0  ma-auto w-100 h-100 object-fit-contain"
      />
      <template v-if="!isImgLoaded">
        <VRow
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="green "
          ></v-progress-circular>
        </VRow>
      </template>
    </VCard>
  </VDialog>
</template>

<script>
import _ from "lodash";
import dialogMixin from "../../mixins/dialogMixin";

export default {
  name: "imgDialog",
  mixins: [dialogMixin],
  data: () => ({
    isImgLoaded: false,
  }),
  props: {
    imgpath: {
      require: true,
    },
  },
  watch:{
    imgpath:{
      deep:true,
      handler(e){
        //  
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close", false);
    },
    showImg() {
      setTimeout(()=>{
 this.isImgLoaded = true;
      },1000)

    },
  },
};
</script>

<style scoped>
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  opacity: 0.7;
  cursor: pointer;
  transition: 0.3s ease;
}
.close:hover {
  opacity: 1;
  transform: rotate(75deg);
}
</style>
