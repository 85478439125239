<template>
  <VDialog v-model="visibility" max-width="400"  :transition="$dialogTransition">
    <VCard>
      <VCardTitle class="headline" :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
        <v-alert text dense :type="statusBlocked ? 'success' : 'error' " class="w-100"  :class="{ 'text-caption': $vuetify.breakpoint.xs }">
          {{blockedDesc.title}}
        </v-alert>
      </VCardTitle>
      <VCardText>
        <span class="text-left d-block"  :class="{ 'text-caption': $vuetify.breakpoint.xs }">
        {{blockedDesc.desc}}
        </span>
        <VSubheader class="pl-0" v-if="!statusBlocked">{{$t('confirmDialog.reasonBlocking')}}</VSubheader>
        <VRow no-gutters v-if="!statusBlocked">
          <VTextarea
                :label="$t('form.msg')"
                :rows="$vuetify.breakpoint.smAndDown ? 3 : 5"
                hide-details="auto"
                v-model="inputMessage"
                class="send-input"
                solo
                counter="200"
                maxlength="200"
                :error-messages="msgErrors"
          />
        </VRow>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn :color="statusBlocked ? 'error' : 'success' " block dark text class="w-100 br-10" @click="visibility = false"  :class="{ 'text-caption': $vuetify.breakpoint.xs }">
              {{$t('btn.cancel')}}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn :color="statusBlocked ? 'success' : 'error' " block class="w-100 br-10" dark @click="onConfirm"  :class="{ 'text-caption': $vuetify.breakpoint.xs }">
               {{$t('btn.confirm')}}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import user from "../../mixins/user";

export default {
 mixins:[user,validationMixin],
  name: "ConfirmDeleteDialog",
    validations: {
      inputMessage: {
        required: requiredIf(function () {
          return !this.statusBlocked
        }),
        },
  },
  data: () => ({
    blockedDesc: {
      title: '',
      desc: '',
    },
    inputMessage:''
  }),
  mounted() {
    this.fetchData()
  },
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    statusBlocked: {
      required: true,
    }
  },
  watch: {
    statusBlocked: 'fetchData'
  },
  methods: {
    onConfirm() {
        this.$v.$touch()
     if(!this.$v.$invalid){
    this.$emit("delete",this.inputMessage);
    this.$v.$reset();
    this.inputMessage = ''
  }

      // this.inputMessage = ''
    },
    fetchData() {
      if(!this.statusBlocked) {
        this.blockedDesc = {
          title:this.$t('confirmDialog.acceptBlockingTitle'),
          desc:this.$t('confirmDialog.acceptBlockingDesc')
        }
      } else {
        this.blockedDesc = {
          title:this.$t('confirmDialog.acceptUnBlockingTitle'),
          desc:this.$t('confirmDialog.acceptUnBlockingDesc')
        }
      }
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    },
        msgErrors() {
      const errors = [];
      if(!this.statusBlocked){
      if (!this.$v.inputMessage.$dirty) {
        return errors;
      }
      // eslint-disable-next-line no-unused-expressions
      !this.$v.inputMessage.required
      && errors.push(this.$t('form.errors.ReasonDescRequired'));
      }
      return errors;
    },
  }
};
</script>

<style scoped></style>
