<template>
  <v-dialog
    v-model="visibility"
    max-width="500"
    content-class="cross__dialog"
    :transition="$dialogTransition"
  >
    <VCard class="pa-3">
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="success"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner" v-if="!loading">
          <VBtn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </VBtn>
        </div>
      </v-slide-x-transition>
      <div>
        <v-row cols="12" class="pa-1" align="center" justify="center">
          <VCol cols="12" v-if="chosenProducts?.length">
            <v-simple-table class="my-table" style="max-height: 250px;" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>{{ $t("table.name") }}</th>
                    <th class="text-center">{{ $t("table.amount") }}</th>
                    <th class="text-center">{{ $t("table.sum") }} {{ $t("paymentPlan.uah") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="product in products" :key="product.uuid">
                    <td>{{ product.product_details.name || "--" }}</td>
                    <td class="text-center">{{ product.items_amount || "0" }}</td>
                    <td class="text-center">
                      {{ product.item_price * product.items_amount || "0.00" }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-row justify="end">
              <div cols="5" class="py-2 px-1 mr-3">
                <span class="text-caption"
                  >{{ $t("cabinet.total") }}: {{ getTotalPrice }}{{ $t("paymentPlan.uah") }}</span
                >
              </div>
            </v-row>
          </VCol>
          <VCol cols="12" class="pb-0 px-2"
            ><VSelect
              dense
              hide-details
              :label="$t('crm.cashiers')"
              outlined
              v-model="cashier"
              :items="cashiers"
              :item-value="'uuid'"
              :item-text="'name'"
              color="success"
              item-color="success"
            ></VSelect
          ></VCol>
          <VCol cols="12" class="px-2">
            <VBtn
              class="success-bg br-10 text-white text-transform-none"
              block
              @click="generateReceipt()"
              :disabled="cashier == ''"
              :loading="loading"
              >{{ $t("crm.generate_check") }}</VBtn
            ></VCol
          >
        </v-row>
      </div>
    </VCard>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import crmFormsService from "@/services/request/crm/crmForms";
import notifications from "@/mixins/notifications";
import language from "@/mixins/language";

export default {
  data: () => ({
    loading: false,
    cashiers: [],
    products: [],
    cashier: ""
  }),
  mixins: [notifications, language],
  props: {
    chosenProducts: {
      required: false
    },
    item: {
      required: true
    },
    visible: {
      require: true
    }
  },
  async mounted() {
    await this.cashiersList();
    this.products = this.chosenProducts;
    this.cashier = this.cashiers.find(cashier => cashier.is_default == 1);
    this.cashier = this.cashier.uuid;
  },
  methods: {
    async cashiersList() {
      this.loading = true;
      try {
        const params = {};
        params.uuid_board = this.item.uuid_board;
        const response = await crmFormsService.getCashiers(params);
        this.cashiers = response;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    onCancel() {
      this.$emit("close", "00000000-0000-0000-0000-000000000000");
    },
    async generateReceipt() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append("uuid_order", this.item.uuid);
        formData.append("uuid_cashier", this.cashier);
        const result = await crmFormsService.generateReceipt(formData);
        if (typeof result !== "undefined") {
          this.$emit("close", result);
          this.setSuccessNotification(this.$t("crm.receipt_generated"));
        }
      } catch (e) {
        const errMessage = (Object.values(JSON.parse(e.error.description))).toString();
        this.setErrorNotification(errMessage);
      } finally {
        this.loading = false;
      }
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
    getTotalPrice() {
      let total = 0;
      this.products.forEach(item => {
        total += item.item_price * item.items_amount;
      });
      return total;
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    }
  }
};
</script>
