export default {
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit('close');
      },
    },
  },
};
