<template>
  <div class="counter__template" :class="{ disabled: isDisabledTemplate || disableProp, dark: $vuetify.theme.dark }">
    <button
      class="btn btn-minus"
      v-ripple
      @click="onMinusValue"
      :class="{ disabled: isDisabledMinus || disableProp }"
    >
      <VIcon class="icon" small :color="!$vuetify.theme.dark ? 'success' : 'grey lighten-1' " :disabled="isDisabledMinus || disableProp">mdi-minus</VIcon>
    </button>
    <input
      ref="myInput"
      class="input"
      @wheel="$event.target.blur()"
      @keypress="validationForNumbers($event)"
      @paste="validationForNumbers($event)"
      v-model="result"
      :readonly="isDisabledTemplate || disableProp"
      :maxlength="maxlength"
    />
    <button
      class="btn btn-plus"
      v-ripple
      @click="onPlusValue"
      :class="{ disabled: isDisabledPlus || disableProp }"
    >
      <VIcon class="icon" small :color="!$vuetify.theme.dark ? 'success' : 'grey lighten-1' " :disabled="isDisabledPlus || disableProp">mdi-plus</VIcon>
    </button>
  </div>
</template>

<script>
import { validationForNumbers } from "@/mixins/helpers";

export default {
  name: "ns-counter",
  data: () => ({
    result: 1,
    isDisabledTemplate: false,
    isDisabledMinus: false,
    isDisabledPlus: false
  }),
  props: {
    disableProp: {
      require: false,
      default: false
    },
    value: {
      require: true
    },
    min: {
      require: true,
      default: null
    },
    max: {
      require: false,
      default: null
    },
    maxlength: {
      require: false,
      default: 3
    }
  },
  mounted() {
    if (this.value) {
      this.result = this.value;
    }
    this.checkDisabled();
  },
  watch: {
    max: {
      deep: true,
      handler(e) {
        this.checkDisabled();
        // this.result = this.min;
      }
    },
    result(e) {
      if (e === 0) {
        // this.result = this.min;
      }
      if (this.max || this.max === 0) {
        this.isDisabledPlus = this.result >= this.max;
      }
      this.isDisabledMinus = this.result <= this.min;
      this.$emit("input", this.result);
      if(e === this.value)return
      this.$emit("change", e);
    }
  },
  methods: {
    validationForNumbers,
    onPaste() {
      this.result = Number(this.result);
      if (this.result >= this.max) {
        this.result = this.max;
      }
      if (this.result < this.min) {
        this.result = this.min;
      }
    },
    onInput(e) {
      this.result = Number(this.result);
      if(!this.max)return
      if (this.result >= this.max) {
        this.result = this.max;
      }
      if (this.result < this.min && e.inputType !== "deleteContentBackward") {
        this.result = this.min;
      }
      if (this.result == '') {
        this.result = 1
      }
    },

    onCheckMax() {
      if (this.max || this.max === 0) {
        if (this.result < this.max) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    onCheckMin() {
      if (this.min || this.min === 0) {
        if (this.result > this.min) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    onPlusValue() {
      const isValidMax = this.onCheckMax();
      if (isValidMax) {
        this.result++;
      }

    },
    onMinusValue() {
      const isValidMin = this.onCheckMin();
      if (isValidMin) {
        this.result--;
      }
    },
    checkDisabled() {
      if (this.max === 0) {
        this.isDisabledTemplate = this.max === 0;
        this.isDisabledPlus = this.max === 0;
        this.isDisabledMinus = this.max === 0;
      } else {
        this.isDisabledTemplate = this.max === 0;
        this.isDisabledPlus = this.result === this.max;
        this.isDisabledMinus = this.result === this.min;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$radius: 5px;

.counter {
  &__template {
    padding: 0;
    display: flex;
    align-items: center;
    border: 1px solid rgba(54, 54, 54, 0.212);
    border-radius: $radius;
    width: 140px;
    // margin: auto;
    &.dark{
      border: 1px solid rgba(158, 158, 158, 0.212);

      .btn{
        background: #3a3838;

      }
      .input{
        color: #e3e3e3;
        background: #444242;


      }
    }
    &.disabled {
      .input {
        background: #e0e0e098;
        color: #797979c4;
      }
    }
    .btn {
      width: 51px;
      border: unset;
      box-shadow: unset;
      background: #ffff;
      outline: unset;
      &-minus {
        border-top-left-radius: $radius;
        border-bottom-left-radius: $radius;
        &.disabled {
          pointer-events: none;
        }
      }
      &-plus {
        border-top-right-radius: $radius;
        border-bottom-right-radius: $radius;
        &.disabled {
          pointer-events: none;
        }
      }
    }
    .icon {
      padding: 12px 11px;
    }
    .input {
      font-size: 14px;
      height: 40px;
      outline: unset;
      padding: 0;
      margin: 0;
      width: 60px;
      text-align: center;
      background: #fafafa;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
