import { mapActions, mapGetters } from 'vuex';
import * as getterTypes from '@/store/modules/auth/types/getters';
import * as actionTypes from '@/store/modules/auth/types/actions';

export default {
  methods: {
    ...mapActions('auth', {
      logOut: actionTypes.LOG_OUT,
    }),
    getUserProperty(property) {
      if (this.getUser) {
        if (this.getUser[property]) return this.getUser[property];
      }
      return '';
    },
  },
  data: () => ({
    owner: 'owner',
    admin: 'admin',
    manager: 'manager',
    client: 'client',
    employee: 'employee',
  }),
  computed: {
    ...mapGetters('auth', {
      getUser: getterTypes.GET_USER_DATA,
      permissions: getterTypes.GET_PERMISSIONS,
    }),
    canEdit() {
      if (this.$route.name === 'clients') {
        return this.permissions.can_edit_client;
      }
      if (this.$route.name === 'employees') {
        return this.permissions.can_edit_employee;
      }
      if (this.$route.name === 'managers') {
        return this.permissions.can_edit_manager;
      }
      if (this.$route.name === 'admins') {
        return this.permissions.can_create_admin;
      }
      if (this.$route.name === 'owners') {
        return this.permissions.can_create_owner;
      }
      if (this.$route.name === 'crm-users') {
        return this.permissions.can_see_crm_users_list;
      }
      return false;
    },
    canBlock() {
      if (this.$route.name === "clients") {
        return this.permissions.can_block_clients;
      }
      if (this.$route.name === "employees") {
        return this.permissions.can_block_employees;
      }
      if (this.$route.name === "managers") {
        return this.permissions.can_block_managers;
      }
      if (this.$route.name === "admins") {
        return this.permissions.can_block_admins;
      }
      if (this.$route.name === "owners") {
        return false
      }
      if (this.$route.name === 'crm-users') {
        return this.permissions.can_block_crm;
      }
      return false;
    },
    canActivate() {
      if (this.$route.name === "clients") {
        return this.permissions.can_activate_clients;
      }
      if (this.$route.name === "employees") {
        return this.permissions.can_activate_employees;
      }
      if (this.$route.name === "managers") {
        return this.permissions.can_activate_managers;
      }
      if (this.$route.name === "admins") {
        return this.permissions.can_activate_admins;
      }
      if (this.$route.name === "owners") {
        return false
      }
      if (this.$route.name === 'crm-users') {
        return this.permissions.can_activate_crm;
      }
      return false;
    },
    canDelete() {
      if (this.$route.name === 'clients') {
        return this.permissions.can_delete_client;
      }
      if (this.$route.name === 'employees') {
        return this.permissions.can_delete_employee;
      }
      if (this.$route.name === 'managers') {
        return this.permissions.can_delete_manager;
      }
      if (this.$route.name === 'admins') {
        return this.permissions.can_delete_admin;
      }
      if (this.$route.name === 'owners') {
        return this.permissions.can_delete_owner;
      }
      if (this.$route.name === 'crm-users') {
        return this.permissions.can_see_crm_users_list;
      }
      return false;
    },
  },
};
