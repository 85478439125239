<template>
  <VCol class="pa-0 pt-1">
    <VCard>
      <VCardText class="pt-3">
        <div
          v-if="!isPrinting && type == !'direct'"
          :class="
            mainItem.options_details
              ? 'flex row justify-content-space-between'
              : 'flex row justify-content-end'
          "
          style="min-height: 60px"
        >
          <chip-tab
            v-if="mainItem.options_details"
            :items="mainItem.options_details"
            :sum_options="sum_options"
            @onClickChipTab="onClickChipTab"
            class="mb-2 ml-3"
            style="max-width: 600px"
            :showCount="true"
          />
          <div class="flex row justify-content-end pt-4">
            <VBtn
              color="primary"
              class="br-10 text-transform-none"
              plain
              @click="onClickMore"
            >
              <VIcon :left="!$vuetify.breakpoint.smAndDown">
                mdi-printer
              </VIcon>
              <div v-if="!$vuetify.breakpoint.smAndDown">
                {{ $t("btn.print") }}
              </div>
            </VBtn>
            <v-switch
              class="ml-4"
              v-model="statusCheckbox"
              :label="$t('btn.all')"
              hide-details
              color="primary"
            />
          </div>
        </div>
        <v-simple-table v-if="!isLoading" :style="isPrinting ? 'padding-right: 1px':''" class="my-table" :dense="!isLarge">
          <template v-slot:default>
            <thead>
              <tr>
                <th v-if="!isPrinting" class="text-left font-weight-bold ">
                  <div class="pb-1 d-flex align-center">
                    <VTextField
                      dense
                      :placeholder="$t('table.ns_code')"
                      v-model="searchNsCode"
                      clearable
                      :loader-height="5"
                      @input="onSearch({ ns_code: searchNsCode })"
                      hide-details
                      solo
                      @keypress="validationForNumbers($event)"
                      @paste="validationForNumbers($event)"
                    />
                    <span
                      v-if="mainItem.amount"
                      class="d-block font-14  pl-3 text-uppercase text-left font-weight-medium"
                      style="color: #676767"
                    >
                      ({{ mainItem.amount }})
                    </span>
                  </div>
                </th>
                <th v-if="isPrinting" class="text-left font-weight-bold ">
                  <div class="pb-1 d-flex align-center">
                    {{$t('table.ns_code')}}
                    <span
                      v-if="mainItem.amount"
                      class="d-block font-14  pl-3 text-uppercase text-left font-weight-medium"
                      style="color: #676767"
                    >
                      ({{ mainItem.amount }})
                    </span>
                  </div>
                </th>
                <th class="text-left font-weight-bold">{{ $t("products.option") }}</th>
                <th v-if="!isPrinting" class="text-left font-weight-bold">
                  <div class="pb-1 d-flex align-center">
                    <VTextField
                      dense
                      :placeholder="$t('table.description')"
                      v-model="description"
                      clearable
                      :loader-height="5"
                      @input="onSearch({ description: description })"
                      hide-details
                      solo
                    />
                    <span
                      v-if="mainItem.amount"
                      class="d-block font-14  pl-3 text-uppercase text-left font-weight-medium"
                      style="color: #676767"
                    >
                      ({{ mainItem.amount }})
                    </span>
                  </div>
                </th>
                <th v-if="isPrinting" class="text-left font-weight-bold">
                  <div class="pb-1 d-flex align-center">
                    {{$t('table.description')}}
                    <span
                      v-if="mainItem.amount"
                      class="d-block font-14  pl-3 text-uppercase text-left font-weight-medium"
                      style="color: #676767"
                    >
                      ({{ mainItem.amount }})
                    </span>
                  </div>
                </th>
                <th class="text-left font-weight-bold" v-if="permissions.not_client">
                  {{ $t("table.sector") }}
                </th>
                <th class="text-left font-weight-bold" v-if="permissions.not_client">
                  {{ $t("table.cell") }}
                </th>
                <th class="text-left font-weight-bold">{{ $t("table.date_of_receipt") }}</th>
                <th v-if="statusCheckbox" class="text-left font-weight-bold">
                  {{ $t("nsCodes.status") }}
                </th>
                <th v-if="!isPrinting && type == 'direct'"></th>
              </tr>
            </thead>

            <tbody name="fade">
              <tr v-if="!items.length" aria-colspan="12">
                <td colspan="7">
                  <h3 class="text-center">{{ $t("table.noData") }}</h3>
                </td>
              </tr>
              <tr v-for="product in items" :key="product.uuid" v-else>
                <td v-if="product.ns_code">
                  <div style="display: flex; justify-content: flex-start; align-items: center;">
                    <div
                      style="max-width: 132px"
                      class="copyTxt"
                      @click="copyToClipboard(product.ns_code)"
                    >
                      {{ product.ns_code }}
                    </div>
                    <v-tooltip v-if="permissions.can_see_history && !isPrinting" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <VBtn
                          v-bind="attrs"
                          v-on="on"
                          class="btn-edit sm mr-3"
                          color="grey"
                          icon
                          :small="!isLarge"
                          @click="showHistory(product.uuid)"
                        >
                          <VIcon :small="!isLarge">
                            mdi-history
                          </VIcon>
                        </VBtn>
                      </template>
                      <span>{{ $t("history.item_history") }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="product.id_state == '810' && !isPrinting && type == !'direct'" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <VBtn
                          v-bind="attrs"
                          v-on="on"
                          class="btn-edit sm mr-3"
                          color="red"
                          icon
                          :small="!isLarge"
                          @click="blockNscode(product)"
                          disabled
                        >
                          <VIcon :small="!isLarge">
                            mdi-lock
                          </VIcon>
                        </VBtn>
                      </template>
                      <span>{{ $t("products.block") }}</span>
                    </v-tooltip>
                    <v-tooltip v-if="product.id_state == '840' && !isPrinting && type == !'direct'" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <VBtn
                          v-bind="attrs"
                          v-on="on"
                          class="btn-edit sm mr-3"
                          color="primary"
                          icon
                          :small="!isLarge"
                          @click="unBlockNscode(product)"
                          disabled
                        >
                          <VIcon :small="!isLarge">
                            mdi-lock-open
                          </VIcon>
                        </VBtn>
                      </template>
                      <span>{{ $t("products.unblock") }}</span>
                    </v-tooltip>
                  </div>
                </td>
                <td>{{ product.option_details.name || "--" }}</td>
                <td :style="(isPrinting || type == 'direct') ? { 'max-width': '90px', 'justify-content': 'column' } : { 'display': 'flex', 'justify-content': 'space-between' }" class="pr-0" style="align-items: center;">
                  <div :style="{ width: statusCheckbox || isPrinting ? '120px' : '200px', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }">
                    {{ product.description }}
                  </div>
                  <v-tooltip v-if="!isPrinting && type == !'direct'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <VBtn
                        v-bind="attrs"
                        v-on="on"
                        class="btn-edit sm mr-3"
                        color="grey"
                        icon
                        :small="!isLarge"
                        @click="openDescDialog(product)"
                      >
                        <VIcon :small="!isLarge">
                          mdi-dots-vertical
                        </VIcon>
                      </VBtn>
                    </template>
                    <span>{{ $t('table.description') }}</span>
                  </v-tooltip>
                </td>
                <td v-if="permissions.not_client">
                  <span>{{ product.sector_details.name }}</span>
                </td>
                <td v-if="permissions.not_client">
                  <span v-if="getCellName(product.cell_details) !== 'undefined'">
                    {{ getCellName(product.cell_details) || "--" }}
                  </span>
                  <VIcon v-else :dense="!isLarge">
                    mdi-help-circle-outline
                  </VIcon>
                </td>
                <td class="text-left">{{ product.time_created | date }}</td>
                <td v-if="statusCheckbox" class="text-center">
                  <v-tooltip v-if="product.id_state == 810 && statusCheckbox" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <VIcon class="mr-1" color="primary" v-bind="attrs" v-on="on">
                        mdi-checkbox-marked
                      </VIcon>
                    </template>
                    {{ $t("products.available") }}
                  </v-tooltip>
                  <v-tooltip
                    v-if="product.id_state == 820 && statusCheckbox && product.uuid_delivery"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VIcon
                        class="mr-1"
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        @click="openOrderDialog(product.uuid_delivery)"
                      >
                        mdi-truck-delivery
                      </VIcon>
                    </template>
                    {{ $t("products.ready_to_ship") }}
                  </v-tooltip>
                  <v-tooltip
                    v-if="product.id_state == 820 && statusCheckbox && !product.uuid_delivery"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <VIcon class="mr-1" color="success" v-bind="attrs" v-on="on">
                        mdi-truck-delivery
                      </VIcon>
                    </template>
                    {{ $t("products.ready_to_ship") }}
                  </v-tooltip>
                  <v-tooltip v-if="product.id_state == 830 && statusCheckbox" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <VIcon class="mr-1" :color="$vuetify.theme.dark ? 'white' : 'black'" v-bind="attrs" v-on="on">
                        mdi-alpha-r-box
                      </VIcon>
                    </template>
                    {{ $t("products.reserved") }}
                  </v-tooltip>
                  <v-tooltip v-if="product.id_state == 840 && statusCheckbox" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <VIcon class="mr-1" color="red" v-bind="attrs" v-on="on">
                        mdi-lock
                      </VIcon>
                    </template>
                    {{ $t("products.blocked") }}
                  </v-tooltip>
                </td>
                  <td v-if="!isPrinting && type == 'direct'">
                    <VBtn icon :disabled="loadingSend" @click="onSendDirect(product)">
                      <VIcon>
                        mdi-send
                      </VIcon>
                    </VBtn>
                  </td>
                <!-- <td v-if="0" class="text-right">
                    <VIcon
                      class="cursor-pointer"
                      style="color:green"
                      @click="onSendItem(product)"
                    >mdi-send
                    </VIcon>
                  </td> -->
              </tr>
              <tr height="20px">
                <td colspan="7">
                  <v-progress-linear
                    v-if="isLoadingFilter"
                    indeterminate
                    color="success"
                  ></v-progress-linear>
                  <div
                    v-if="!loadMoreDisabled && !searchLoading"
                    v-observer="{ nextPage: nextPage }"
                    class="observer"
                  ></div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="products-loader__wrapper" v-if="isLoading">
          <div class="products-loader__inner"></div>
          <v-progress-circular
            indeterminate
            color="success"
          ></v-progress-circular>
        </div>
      </VCardText>
    </VCard>
    <ns-code-description
      v-if="visibleDescription"
      :visible="visibleDescription"
      @close="visibleDescription = false"
      :product="product"
      @onUpdate="onUpdate"
    />
    <ShowItemInfoDialog
      :visible="visibleInfo"
      @close="visibleInfo = false"
      :itemCod="itemCod"
      v-if="visibleInfo"
    />
    <DialogWrapper
      @close="editOutDialog = false"
      v-if="editOutDialog"
      :visible="editOutDialog"
      :typeDialog="'edit'"
    >
    <OrderDialog
      :uuidItem="product.uuid_deliver"
      :typeDialog="'edit'"
      settingDialog="supply"
    ></OrderDialog>
    </DialogWrapper>
  </VCol>
</template>

<script>
import _ from "lodash";
import depotService from "@/services/request/depot/depotService";
import { uniqueByUUID, validationForNumbers } from "@/mixins/helpers";
import SizeUi from "@/mixins/SizeUi";
import notifications from "@/mixins/notifications";
import user from "@/mixins/user";
import loader from "@/mixins/loader";
import paginationMixin from "@/mixins/paginationMixin";
import NsCodeDescription from "@/views/depots/NsCodeDescription.vue";
import OrderDialog from "@/components/orderDialog/OrderDialog.vue";
import EventBus from "@/events/EventBus";
import DialogWrapper from "@/components/orderDialog/DialogWrapper.vue";
import ChipTab from "@/components/common/chipTab.vue";
import {mapGetters} from "vuex";
import ShowItemInfoDialog from "@/components/layout/ShowItemInfoDialog.vue";
import supplyService from '@/services/request/supply/supplyService';

export default {
  mixins: [SizeUi, notifications, user, loader, paginationMixin],
  components: {
    ShowItemInfoDialog,
    ChipTab,
    NsCodeDescription,
    OrderDialog,
    DialogWrapper
  },
  data: () => ({
    loadingSend:false,
    visibleInfo: false,
    editOutDialog: false,
    visibleDescription: false,
    createDialog: false,
    searchString: "",
    page: 1,
    perPageList: [5, 10, 15],
    total: 24,
    lastPage: 10,
    paginatedProducts: [],
    firstNumber: "",
    secondNumber: "",
    options: [],
    isOptionsMenu: false,
    itemCod: "",
    status: 810,
    searchNsCode: "",
    statusCheckbox: false,
    description: "",
    items: [],
    loadMoreDisabled: false,
    searchLoading: false,
    product: {
      uuid_deliver: ""
    },
    is_option_uuid: "",
    isEditing: false,
    isLoadingFilter: false,
    sum_options: 0
  }),
  props: {
    uuid_delivery:{
      require: true,
      type: String,
      default: ""
    },
    uuid_prod:{
      require: true,
      type: String,
    },
    type:{
      require: true,
      type: String,
      default: ""
    },
    mainItem: {
      require: true
    },
    isPrinting: {
      require: true
    },
    uuid_option: {
      require: true,
      type: String,
      default: ""
    },
    options_available: {
      require: true,
    },
  },
  async mounted() {
    EventBus.$on("added-product", async () => {
      await this.getItems();
    });
    EventBus.$on("product-updated", async () => {
      await this.getItems();
    });
    await this.getItems();
  },
  methods: {
    async blockNscode(product){
      try {
        this.isLoading = true;

        this.status = '840'

        const formData = new FormData();
        formData.append("id_state", this.status);
        formData.append("uuid", product.uuid);

        await depotService.updateSectorsList(formData);

        await this.onSearch()

        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    async unBlockNscode(product){
      try {
        this.isLoading = true;
        this.status = '810'

        const formData = new FormData();
        formData.append("id_state", this.status);
        formData.append("uuid", product.uuid);

        await depotService.updateSectorsList(formData);

        await this.onSearch()
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    validationForNumbers,
    showHistory(e) {
      this.visibleInfo = true;
      this.itemCod = e;
    },
    async onSendDirect(product) {
      try {
        this.loadingSend = true
        const formData = new FormData()

        formData.append('uuid_delivery', this.uuid_delivery);
        formData.append('uuid_item', product.uuid);
        await supplyService.addDeliveryItem(formData);
        this.loadingSend = false
        this.setSuccessNotification('Відправка успішно створена');
        this.$emit('close')
        EventBus.$emit('on-handle-chosen-product',this.uuid_delivery)
      } catch(e) {
        this.loadingSend = false
        console.log(e)
      }
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e)
      }
    },
    async emitPageChange() {
      try {
        this.isLoadingFilter = true;
        await this.getItems();
      } catch (e) {
        console.log(e);
        this.isLoadingFilter = false;
      } finally {
        this.setLoading(false);
      }
    },
    openOrderDialog(e) {
      this.product.uuid_deliver = e;
      this.editOutDialog = true;
    },
    async onUpdate(product) {
      try {
        this.isLoading = true;

        const formData = new FormData();
        formData.append("description", product.description);
        formData.append("uuid", product.uuid);

        await depotService.updateSectorsList(formData);

        await this.onSearch()

        this.isLoading = false;

      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },
    openDescDialog(e) {
      this.product = e;
      this.visibleDescription = true;
    },
    async getItems(params = {}) {
      try {
        this.isLoadingFilter = true;
        if (this.statusCheckbox) {
          this.status = "810||820||830||840";
        } else {
          this.status = 810;
        }
        if(this.isPrinting) {
          const newItems = await depotService.getMyProducts({
            ...params,
            uuid_product: this.$route.params.barCode,
            id_state: this.status,
          });
          this.items.push(...newItems);
          this.items = uniqueByUUID(this.items);
        } else if(this.uuid_prod){
          const newItems = await depotService.getMyProducts({
            uuid_product: this.uuid_prod,
            uuid_option: this.uuid_option ? this.uuid_option : '00000000-0000-0000-0000-000000000000',
            id_state: this.status,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage
          });
          this.items.push(...newItems);
          this.items = uniqueByUUID(this.items);
          this.loadMoreDisabled = newItems.length < this.perPage;
        } else if(this.is_option_uuid){
          const newItems = await depotService.getMyProducts({
            ...params,
            uuid_product: this.$route.params.barCode,
            uuid_option: this.is_option_uuid,
            id_state: this.status,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage
          });
          this.items.push(...newItems);
          this.items = uniqueByUUID(this.items);
          this.loadMoreDisabled = newItems.length < this.perPage;
        } else if(!this.is_option_uuid){
          const newItems = await depotService.getMyProducts({
            ...params,
            uuid_product: this.$route.params.barCode,
            id_state: this.status,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage
          });
          this.items.push(...newItems);
          this.items = uniqueByUUID(this.items);
          this.loadMoreDisabled = newItems.length < this.perPage;
        } else if(!this.isPrinting){
          const newItems = await depotService.getMyProducts({
            ...params,
            uuid_product: this.$route.params.barCode,
            id_state: this.status,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage
          });
          this.items.push(...newItems);
          this.items = uniqueByUUID(this.items);
          this.loadMoreDisabled = newItems.length < this.perPage;
        }
        this.isLoadingFilter = false;
        //
        // this.items = Object.values(this.items);
      } catch (e) {
        this.isLoadingFilter = false;
        this.searchLoading = false;
        console.log(e);
      }
    },
    async onSearch(params) {
      try {
        this.isLoadingFilter = true;
        this.searchLoading = true;
        this.page = 1;
        if (this.statusCheckbox) {
          this.status = "810||820||830||840";
        } else {
          this.status = 810;
        }
        if(this.uuid_prod){
          const newItems = await depotService.getMyProducts({
            ...params,
            uuid_product: this.uuid_prod,
            uuid_option: this.uuid_option ? this.uuid_option : '00000000-0000-0000-0000-000000000000',
            id_state: this.status,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            uselike: params ? true : false
          });
          this.items = [];
          //
          // this.items = Object.values(this.items);
          this.items = uniqueByUUID(this.items);
          this.loadMoreDisabled = newItems.length < this.perPage;
          this.isLoadingFilter = false;
        } else if(!this.is_option_uuid){
          const newItems = await depotService.getMyProducts({
            ...params,
            uuid_product: this.$route.params.barCode,
            id_state: this.status,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            uselike: true
          });
          this.items = [];
          this.items.push(...newItems);
          //
          // this.items = Object.values(this.items);
          this.items = uniqueByUUID(this.items);
          this.loadMoreDisabled = newItems.length < this.perPage;
          this.isLoadingFilter = false;
        } else if(this.is_option_uuid){
          const newItems = await depotService.getMyProducts({
            ...params,
            uuid_product: this.$route.params.barCode,
            uuid_option: this.is_option_uuid,
            id_state: this.status,
            limit: this.perPage,
            offset: (this.page - 1) * this.perPage,
            uselike: params ? true : false
          });
          this.items = [];
          this.items.push(...newItems);
          //
          // this.items = Object.values(this.items);
          this.items = uniqueByUUID(this.items);
          this.loadMoreDisabled = newItems.length < this.perPage;
          this.isLoadingFilter = false;
        }
      } catch (e) {
        this.isLoadingFilter = false;
        this.searchLoading = false;
        console.log(e);
      } finally {
        this.isLoadingFilter = false;
        this.searchLoading = false;
      }
    },
    onClickChipTab(optionName) {
      if (optionName === this.$t("btn.all")) {
        this.is_option_uuid = "";
        return this.onSearch();
      }
      this.mainItem.options_details.forEach((item) => {
        if(item.name === optionName) {
          this.is_option_uuid = item.uuid;
        }
      });
      this.onSearch();
    },
    onClickMore() {
      const route = this.$router.resolve({
        name: "barcode-details",
        params: {
          id: this.mainItem.uuid,
          lang: this.currentLanguage.key
        }
      });
      window.open(route.href, "_blank");
    },
    getCellName(cellDetails) {
      return cellDetails?.name;
    },
  },
  watch: {
    statusCheckbox: {
      handler(e) {
        this.onSearch();
      }
    },
    mainItem: {
      handler(e) {
        this.sum_options = 0;
        this.mainItem.options_details.forEach((item) => {
          this.sum_options += Number(item.amount);
        });
      }
    }
  },
  computed: {
    ...mapGetters(["currentLanguage"]),
  }
};
</script>

<style></style>
