// eslint-disable-next-line
import { DEFAULT_STORAGE } from "./constants";

export default {
  data: () => ({
    localStorageName: "ns-data",
    DEFAULT_STORAGE: DEFAULT_STORAGE
  }),
  methods: {
    deepSearchByKey(object, originalKey, matches = []) {
      if (object != null) {
        if (typeof object == "object") {
          // eslint-disable-next-line
          for (const key of Object.keys(object)) {
            if (key == originalKey) {
              matches.push(object);
            } else {
              this.deepSearchByKey(object[key], originalKey, matches);
            }
          }
        }
      }

      return matches[0];
    },
    setStorageItem() {},
    getStorageItem(keyName) {
      const result = this?.deepSearchByKey(
        JSON.parse(localStorage.getItem(this.localStorageName)),
        keyName
      );
      if(result){
        return result[keyName];
      }else{
        return null;
      }
    }
  }
};
