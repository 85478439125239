<template>
  <svg
    :width="27"
    :height="27"
    :viewBox="'0 0 27 30'"
    xmlns="http://www.w3.org/2000/svg"
    :fill="fill"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M12.1757 25.9772L4.28358 22.7986C4.16675 22.7529 4.08887 22.65
        4.08887 22.5242L4.10185 15.1724C4.10185 14.9552 4.36146 14.818 4.58213
        14.898L12.4613 18.0765C12.5781 18.1223 12.656 18.2252 12.656 18.3509L12.643
        25.7028C12.643 25.92 12.3964 26.0572 12.1757 25.9772Z"/>
      <path d="M21.8333 13.7546L13.9671 10.5875C13.7334 10.4961
      13.6945 10.2217 13.9022 10.0845L17.6016 7.53476C17.7055 7.46616 17.8353
       7.45473 17.9521 7.50046L25.8183 10.6676C26.0519 10.759 26.0909 11.0334
        25.8832 11.1706L22.1838 13.7203C22.0799 13.7889 21.9501 13.8004 21.8333
         13.7546Z" />
      <path d="M21.5346 22.8328L13.6294 25.9771C13.4088 26.0685
       13.1492 25.9199 13.1492 25.7027L13.1622 18.3509C13.1622 18.2365
       13.24 18.1336 13.3569 18.0765L21.262 14.9322C21.4827 14.8408
        21.7423 14.9894 21.7423 15.2066L21.7293 22.5584C21.7423 22.6728
         21.6644 22.7757 21.5346 22.8328Z"/>
      <path d="M0.0649026 10.6217L7.94408 7.47751C8.06091
       7.43178 8.19071 7.44321 8.29456 7.51181L11.981 10.0729C12.1757
        10.2101 12.1368 10.4845 11.9161 10.576L4.03694 13.7203C3.92012
         13.766 3.79031 13.7546 3.68647 13.686L0 11.1248"/>
      <path d="M12.9415 17.7793C12.9286 17.7793 12.9156
      17.7793 12.9026 17.7678L3.99797 14.2692C3.95903 14.2577 3.94604
       14.2234 3.94604 14.2006C3.94604 14.1777 3.97201 14.1434 3.99797
        14.132L12.9026 10.6333C12.9286 10.6218 12.9545 10.6218 12.9805
         10.6333L21.8851 14.132C21.9241 14.1434 21.9371 14.1777 21.9371
         14.2006C21.9371 14.2234 21.9111 14.2577 21.8851 14.2692L12.9805
          17.7678C12.9675 17.7678 12.9545 17.7793 12.9415 17.7793ZM4.25758
           14.1891L12.9415 17.6078L21.6255 14.1891L12.9415 10.7705L4.25758
           14.1891Z"/>
      <path d="M12.1499 14.1891L12.5653 3.31573H13.3181L13.7205 14.1891"/>
      <path d="M15.421 4.59636L12.9288 3.80744L10.4365 4.59636C10.3586
      4.61922 10.2807 4.55062 10.3197 4.48202L12.8249 0.0572188C12.8639
      -0.0228164 12.9937 -0.0228164 13.0456 0.0572188L15.5508 4.48202C15.5768
       4.55062 15.5119 4.61922 15.421 4.59636Z"/>
    </g>
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'PackageOut',
};
</script>

<style scoped>

</style>
