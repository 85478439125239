// eslint-disable-next-line import/no-cycle
import depotService from '@/services/request/depot/depotService';
import * as actions from './types/actions';
import * as mutations from './types/mutations';

export default {
  [actions.FETCH_CELLS]: async ({ commit,state }) => {
      if(state.cells.length)return
      const cells =  await depotService.getCellsList();
      commit(mutations.SET_CELLS, cells);
  },
  [actions.FETCH_PRODUCTS]: async ({ commit,state }) => {
      if(state.products.length)return
      const products = await depotService.getAllItems({
        id_state: 810,
      });
      commit(mutations.SET_PRODUCTS, products);
  },
  [actions.FETCH_SECTORS]: async ({ commit,state }) => {
      if(state.sectors.length)return
      const sectors = await depotService.getSectorsList();

      commit(mutations.SET_SECTORS, sectors);
  },
  [actions.FETCH_DEPORTS]: async ({ commit,state }) => {
     
      if(state.deports.length)return
      const deports = await depotService.getDepotsList();

      commit(mutations.SET_DEPORTS, deports);
  },
};
