<template>
  <div class="orderDialog__availableProducts">
    <v-row class="pa-0 ma-0" justify="space-between" align="center">
      <v-col cols="auto" class="px-0">
        <v-btn depressed plain small color="success" @click="onHandleTab('edit')">
          <v-icon left>mdi-arrow-left</v-icon>
          {{ $t("btn.back") }}
        </v-btn>
      </v-col>
      <v-col cols="8" class="px-0">
        <chip-tab
          :items="chipTabsItems"
          @onClickChipTab="onClickChipTab"
          :loadingChips="loadingChips || Boolean(searchString)"
          :resetChip="Boolean(searchString) || isChipReset"
        />
      </v-col>

      <v-col cols="auto" class="pl-0 pr-1">
        <VTextField
          prepend-inner-icon="mdi-magnify"
          dense
          :label="$t('form.search')"
          v-model="searchString"
          @input="onSearchProduct"
          hide-details="auto"
          clearable
          ref="searchField"
          solo
          :loading="isLoadingSearch"
          @keypress="validationForTitle($event)"
          @paste="validationForTitle($event)"
        />
      </v-col>
    </v-row>
    <VSimpleTable
      fixed-header
      class="my-table supply"
      :height="!$vuetify.breakpoint.smAndDown ? '360px' : '250px'"
      dense
      v-if="products.length && !isloadingProducts"
    >
      <thead>
      <tr>
        <th class="x-sm"></th>
        <th>
          <v-row
            align="center"
            justify="start"
            class="cursor-pointer"
            @click="nameFilterA = !nameFilterA"
          >Назва
            <div class="d-flex flex-column" align="center" no-gutters>
              <v-icon dense style="line-height:0.4" :color="getColorForUp">
                mdi-menu-up
              </v-icon>
              <v-icon dense style="line-height:0.4" :color="getColorForDown">
                mdi-menu-down
              </v-icon>
            </div>
          </v-row>
        </th>
        <th v-if="!isCRMProducts">{{ $t("table.ns_code") }}</th>
        <th>{{ $t("table.bar_code") }}</th>
        <th>{{ $t("table.option") }}</th>
        <th>{{ $t("table.amount") }}</th>
        <th v-if="settingDialog === 'crm'">
          {{ `${$t("table.price")} (${$t("paymentPlan.uah")})` }}
        </th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="product in products" :key="product.uuid">
        <td class="x-sm">
          <img
            v-if="
                (!product?.product_details?.path_img_thumb && !product?.path_img_thumb) && isImgLoaded || !imagesCheckbox
              "
            :src="require('@/assets/images/supply-item.png')"
            class="image-placeholder"
          />
          <v-img
            v-else
            :src="product?.product_details?.path_img_thumb || product?.path_img_thumb"
            class="image "
            @click="showImgDialog(product)"
            v-show="isImgLoaded"
            @load="showImg"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  :size="20"
                  :width="2"
                  color="green lighten-1"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </td>
        <td
            class="copyTxt"
            @click="
              copyToClipboard(
                product?.product_details?.name ? product?.product_details?.name : product?.name
              )
            "
          >
            {{ product?.product_details?.name ? product?.product_details?.name : product?.name }}
          </td>
        <td v-if="!isCRMProducts" class="text-center">
          <div v-if="product.options_details">
            <div
              v-if="product.uuid === isDisabledSend(product, true).uuid && !isDisabledSend(product, true).hasOption">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    v-if="getUser.is_ns_direct_send"
                    icon
                    color="success"
                    disabled
                    style="pointer-events: all"
                  >
                    <v-icon>mdi-format-list-bulleted</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("supply.choose_option") }}</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-btn
                v-if="getUser.is_ns_direct_send"
                icon
                color="success"
                @click="onClickDetailsOption(product)"
              >
                <v-icon>mdi-format-list-bulleted</v-icon>
              </v-btn>
            </div>
          </div>
          <div v-else class="text-center">
            <v-btn
              v-if="getUser.is_ns_direct_send"
              icon
              color="success"
              :disabled="isCRMProducts"
              @click="onClickDetails(product)"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
          </div>
        </td>
        <td
            class="copyTxt"
            @click="
              copyToClipboard(
                product?.product_details?.bar_code
                  ? product?.product_details?.bar_code
                  : product?.bar_code
              )
            "
          >
            {{
              product?.product_details?.bar_code
                ? product?.product_details?.bar_code
                : product?.bar_code
            }}
          </td>
        <td class="lg">
          <VSelect
            v-if="product.options_details"
            :items="getOptionDetailsList(product)"
            item-text="name"
            item-value="uuid"
            :placeholder="$t('supply.choose_option')"
            :no-data-text="$t('table.noData')"
            v-model="checkOption"
            hide-details="auto"
            dense
            outlined
            color="success"
            item-color="success"
          >
            <template slot="item" slot-scope="{ item }">
              {{ item.name }}
              <span style="color: #4FAE3B;padding-left: 5px;">
                  {{ isAccessDeportInfo ? `(${item.available || 0})` : "" }}
                </span>
            </template>
            <template slot="selection" slot-scope="{ item }">
              {{ item.name }}
              <span style="color: #4FAE3B;padding-left: 5px;">
                  {{ isAccessDeportInfo ? `(${item.available || 0})` : "" }}
                </span>
            </template>
          </VSelect>
          <span v-else>
              {{ $t("supply.available") }}
              {{ isAccessDeportInfo ? `(${product.available || 0})` : "" }}
            </span>
        </td>
        <td>
          <!-- <VTextField type="number" dense outlined class="br-10" hide-details min="1" :max="product.available" v-model="product.count" /> -->
          <div v-if="product.options_details">
            <ns-counter
              v-model="product.count"
              :min="1"
              :disableProp=" product.uuid === isDisabledSend(product, true).uuid &&
                      !isDisabledSend(product, true).hasOption"
              :max="
                settingDialog == 'supply'
                  ? typeof product.options_details !== 'undefined'
                    ? checkOption
                      ? Number(optionItemAvailable)
                      : 0
                    : Number(product.available)
                  : 9999
              "
            />
          </div>
          <div v-else>
            <ns-counter
              v-model="product.count"
              :min="1"
              :max="
                settingDialog == 'supply'
                  ? typeof product.options_details !== 'undefined'
                    ? checkOption
                      ? Number(optionItemAvailable)
                      : 0
                    : Number(product.available)
                  : 9999
              "
            />
          </div>
        </td>
        <td v-if="settingDialog === 'crm'">
            <span class="text-green font-weight-bold">
              {{
                product?.product_details?.sell_price
                  ? product?.product_details?.sell_price || "--"
                  : product?.cost || "--"
              }}</span
            >
        </td>

        <td class="text-center">
          <div v-if="product.options_details">
            <div
              v-if="product.uuid === isDisabledSend(product, true).uuid && !isDisabledSend(product, true).hasOption" class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="settingDialog === 'crm'"
                    v-on="on"
                    v-bind="attrs"
                    disabled
                    icon
                    style="pointer-events: all"
                  >
                    <v-icon>mdi-phone-plus-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("supply.choose_option") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="settingDialog === 'crm'"
                    v-on="on"
                    v-bind="attrs"
                    disabled
                    color="success"
                    hide-details
                    icon
                    style="pointer-events: all"
                  >
                    <v-icon>mdi-plus-box-multiple</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("supply.choose_option") }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    disabled
                    icon
                    style="pointer-events: all"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("supply.choose_option") }}</span>
              </v-tooltip>
            </div>
            <div v-else class="d-flex align-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="settingDialog === 'crm'"
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="additionalSellOption(product)"
                    style="pointer-events: all"
                  >
                    <v-icon>mdi-phone-plus-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("crm.additional_sales") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="standaloneSellOption(product)"
                    style="pointer-events: all"
                  >
                    <v-icon>mdi-plus-box-multiple</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("crm.is_standalone") }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    icon
                    @click="addProductOption($event, product, 'addProduct')"
                    style="pointer-events: all"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("supply.add_itemBtn") }}</span>
              </v-tooltip>
            </div>
          </div>
          <div v-else class="d-flex align-center text-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="settingDialog === 'crm'"
                  v-on="on"
                  :disabled="isDisabledSend(product)"
                  v-bind="attrs"
                  icon
                  @click="additionalSell(product)"
                  style="pointer-events: all"
                >
                  <v-icon>mdi-phone-plus-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("crm.additional_sales") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :disabled="isDisabledSend(product)"
                  icon
                  @click="standaloneSell(product)"
                  style="pointer-events: all"
                >
                  <v-icon>mdi-plus-box-multiple</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("crm.is_standalone") }}</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :disabled="isDisabledSend(product)"
                  icon
                  @click="onChoseByBarCode($event, product, 'addProduct')"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("supply.add_itemBtn") }}</span>
            </v-tooltip>
          </div>
        </td>
      </tr>
      <tr :height="$vuetify.breakpoint.lgAndUp ? '20px' : ''">
        <div v-observer="{ nextPage: nextPage }" class="observer" v-if="!loadMoreDisabled"></div>
      </tr>
      </tbody>
    </VSimpleTable>
    <div v-if="isloadingNextProducts">
      <v-progress-linear indeterminate/>
    </div>

    <div class="text-center mt-1" v-if="!products.length && !isloadingProducts">
        <div @click="$router.push({ name: 'catalog' }).catch(() => {})" class="text-center copyTxt">
          {{ 'Додайте товари в "Каталог товарів"' }}
        </div>
    </div>

    <div class="products-loader__wrapper" v-if="isloadingProducts">
      <div class="products-loader__inner"></div>
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
        :width="5"
      ></v-progress-circular>
    </div>
    <imgDialog :visible="imgDialog" @close="imgDialog = false" :imgpath="imgpath"/>

    <send-direct-dialog :options_available="optionItemAvailable" :uuid_delivery="inputCrmItem.uuid"
                        :uuid_option="checkOption" v-if="sendDirectVisibile"
                        :visible="sendDirectVisibile" @close="sendDirectVisibile = false"
                        :item="choosenProducDetail"/>
  </div>
</template>

<script>
import _ from "lodash";
import crmService from "@/services/request/crm/crmService";
import user from "@//mixins/user";
import EventBus from "@/events/EventBus";
import supplyService from "@/services/request/supply/supplyService";
import notifications from "@/mixins/notifications";
import productLismMixins from "./productListMixin";
import depotService from "@/services/request/depot/depotService";
import chipTab from "@/components/common/chipTab.vue";
import ImgDialog from "../../../../products/imgDialog.vue";
import sendDirectDialog from '../../dialogs/sendDirectDialog.vue';
import {validationForTitle} from "@/mixins/helpers";

export default {
  mixins: [productLismMixins, notifications, user],
  data: () => ({
    tableAvaibleProductsFilter: {},
    nameFilterA: null,
    optionItemAvailable: "",
    isImgLoaded: false,
    products: [],
    chipTabsItems: {},
    loadingChips: false,
    searchString: "",
    isChipReset: false,
    isLoadingSearch: false,
    isloadingNextProducts: false,
    isloadingProducts: true,
    perPage: 10,
    imgpath: [],
    imgDialog: false,
    isStandalone: false,
    isAdditionSell: false,
    checkOption: "",
    sendDirectVisibile: false,
    choosenProducDetail: {},
    productAdd: []
  }),
  components: {
    chipTab,
    ImgDialog,
    sendDirectDialog
  },
  mounted() {
    this.isStandalone = false;
    this.isAdditionSell = false;
    this.onStart();
  },
  props: ["settingDialog", "item", "isAccessDeportInfo", "inputCrmItem", "isCRMProducts", "imagesCheckbox", "availableItemsCheckbox"],
  methods: {
    validationForTitle,
    additionalSell(product) {
      product.isAdditionSell = true;
      this.productAdd = product;
      this.onChoseByBarCode(false, this.productAdd, 'addProduct')
    },
    additionalSellOption(product) {
      product.isAdditionSell = true;
      this.productAdd = product;
      this.addProductOption(false, this.productAdd, 'addProduct')
    },
    standaloneSell(product) {
      product.isStandalone = true;
      this.productAdd = product;
      this.onChoseByBarCode(false, this.productAdd, 'addProduct')
    },
    standaloneSellOption(product) {
      product.isStandalone = true;
      this.productAdd = product;
      this.addProductOption(false, this.productAdd, 'addProduct')
    },
    onClickDetails(product) {
      this.checkOption = '00000000-0000-0000-0000-000000000000';
      this.choosenProducDetail = product;
      this.sendDirectVisibile = true;
    },
    onClickDetailsOption(product) {
      this.choosenProducDetail = product;
      this.sendDirectVisibile = true;
    },
    isDisabledSend(product, isOptions = false) {
      const availableNum = Number(product.available);
      const amountNum = Number(product.count);
      if (!isOptions) {
        if (this.settingDialog === "crm") return false;
        return amountNum > availableNum;
      } else {
        if ("options_details" in product) {
          const productOption = product?.options_details.filter(
            item => item.uuid === this.checkOption
          );
          const result = {
            uuid: product.uuid,
            hasOption: Boolean(productOption.length)
          };
          return result;
        }
      }
    },
    showImg() {
      setTimeout(() => {
        this.isImgLoaded = true;
      }, 1000);
    },
    showImgDialog(product) {
      this.imgpath = product.product_details.path_img;
      this.imgDialog = true;
    },
    onStart() {
      this.getProducts();
      this.getChipTabsItems();
    },
    onHandleTab(chosenTab) {
      EventBus.$emit("on-handle-product-tab", chosenTab);
    },
    onSearchProduct: _.debounce(async function () {
      if (!this.searchString) {
        this.categoryChipTab = null;
      }
      if (this.searchString) {
        this.isLoadingSearch = true;
        if (this.isCRMProducts) {
          await this.getProducts({
            name: this.searchString,
            uselike: true
          });
        } else {
          await this.getProducts({
            product_details__name: this.searchString,
            uselike: true
          });
        }
        this.oldSerach = this.searchString;
        setTimeout(() => {
          this.isLoadingSearch = false;
        }, 300);
      } else {
        await this.getProducts();
      }
      this.$refs.searchField.focus();
    }, 500),
    async onClickChipTab(e) {
      this.categoryChipTab = e;
      this.isloadingProducts = true;
      this.tableAvaibleProductsFilter = {};
      if (e === this.$t("btn.all")) {
        if (this.searchString) {
          this.searchString = "";
        }
        await this.getProducts();
      } else {
        if (this.searchString) {
          this.searchString = "";
        }
        const key = this.isCRMProducts ? "name" : "product_details__name";
        await this.getProducts({
          [key]: e,
          uselike: true
        });
      }
      this.nameFilterA = null;
      this.isloadingProducts = false;
      // this.loadingChips = false;
    },
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
    async getChipTabsItems() {
      try {
        const params = {};
        if (!this.permissions.client) {
          params.uuid_user = this.item.uuid_user;
        }
        let response = null;
        if (this.settingDialog === "crm") {
          if (this.isCRMProducts) {
            response = await crmService.getItemsChipListCRM();
          } else {
            response = await crmService.getItemsChipList(this.uuid_board);
          }
        } else {
          response = await depotService.getItemsChipList(params);
        }
        const chipItems = response;
        this.chipTabsItems = chipItems;
        if (this.chipTabsItems) {
          this.chipTabsItems = this.chipTabsItems.sort((a, b) => b.count - a.count);
        }
      } catch (e) {
        console.log(e)
      }
    }
  },
  watch: {
    checkOption(chosenOption) {
      this.products.forEach(products => {
        if (products.options_details) {
          const option = products.options_details.filter(option => option.uuid === chosenOption);
          if (option.length > 0) {
            this.optionItemAvailable = option[0].available;
          }
        }
      });
    },
    searchString(e) {
      if (!e) {
        this.getProducts();
      }
    },
    availableItemsCheckbox: {
      handler() {
        this.getProducts();
      }
    }
  }
};
</script>

<style></style>
