// eslint-disable-next-line import/no-cycle
/* eslint-disable import/no-cycle */
import * as actions from './types/actions';
import * as mutations from './types/mutations';

export default {
    [actions.FETCH_CRM_CHECK]: async ({ commit }, value = true) => {
        commit(mutations.SET_CRM_CHECK, value);
    },
    [actions.FETCH_DELIVERIES_CHECK]: async ({ commit }, value = true) => {
        commit(mutations.SET_DELIVERIES_CHECK, value);
    },
};