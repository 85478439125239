import * as actionTypes from './types/actions';
import * as mutationTypes from './types/mutations';

export default {
  [actionTypes.SET_UUID_BOARD]: ({ commit }, columnId) => {
    commit(mutationTypes.SET_UUID_BOARD, columnId);
  },
  [actionTypes.SET_UUID_TAB]: ({ commit }, columnId) => {
    commit(mutationTypes.SET_UUID_TAB, columnId);
  },
};
