// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = "/users/plans";
export default {
  async usersPlansList(payment) {
    const response = await requestService.get(`${prefix}/list`,payment);
    return response?.data?.object;
  },
  async usersPlansInfo(payload) {
    const response = await requestService.get(`${prefix}/info/${payload}`,{},{},false);
    return response?.data?.object;
  },
  async usersPlansUpdate(payload) {
    const response = await requestService.post(`${prefix}/update`,payload);
    return response?.data?.object;
  },
  async usersPlansAdd(payload) {
    const response = await requestService.post(`${prefix}/add`,payload);
    return response?.data?.object;
  },
  async usersPlansDefault() {
    const response = await requestService.get(`${prefix}/default`);
    return response?.data?.object;
  },
  async usersPlansSetDefault(payload) {
    const response = await requestService.patch(`${prefix}/default/${payload}`);
    return response?.data?.object;
  }
};
