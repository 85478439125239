<template>
  <v-dialog v-model="visibility" max-width="500">
    <v-card>
      <v-card-title
        class="headline"
        :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
      >
        <v-alert text dense :type="'info'" class="w-100" color="success">
          {{ $t('generate_ttn.generate_ttn')}}
        </v-alert>
      </v-card-title>
      <v-card-text>
        <div style="font-size: 16px" class="font-weight-bold mb-2">
          {{ $t('generate_ttn.question_generate_ttn')}}
        </div>
        <v-row no-gutters justify="space-between">
          <div>{{ $t('generate_ttn.recipient')}}:</div>
          <div class="font-weight-bold">
            {{ inputDelivery.name }} {{ inputDelivery.surname }} {{ inputDelivery.middlename}}
          </div>
        </v-row>
        <v-row no-gutters justify="space-between">
          <div>{{ $t("form.phone_receiver") }}:</div>
          <div class="font-weight-bold">{{ inputDelivery.phone }}</div>
        </v-row>
        <v-row no-gutters justify="space-between">
          <div v-if="radioServiceType == 'WarehouseWarehouse'">
            {{ $t("orderDialog.warehouse") }}:
          </div>
          <div v-if="radioServiceType == 'WarehouseDoors'">
            {{ $t("orderDialog.address") }}:
          </div>
          <v-col
            class="font-weight-bold col-8 pa-0 text-right"
            v-if="radioServiceType == 'WarehouseWarehouse'"
          >
            {{ inputCrmItem.delivery_address_details?.name_warehouse }}
          </v-col>
          <v-col
            class="font-weight-bold col-8 text-right"
            v-if="radioServiceType == 'WarehouseDoors'"
          >
            {{ inputAddress.city_address.Description }},
            {{ inputAddress.city_street.Description }},
            {{ inputAddress.city_house
            }}<span v-if="inputAddress.city_flat">,</span>
            {{ inputAddress.city_flat }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0 my-0" cols="12">
            <v-autocomplete
              :items="cargoList"
              outlined
              dense
              class="br-10"
              :label="$t('generate_ttn.description_of_the_deliveries')"
              return-object
              v-on:keypress="validationForDepartment($event)"
              @paste="validationForDepartment($event)"
              :item-text="'Description'"
              hide-no-data
              no-filter
              :search-input.sync="searchCargoDescQuery"
              v-model="ttn.CargoDescription"
              :error-messages="CargoDescriptionError"
              color="success"
              item-color="success"
            >
              <template v-slot:append-item>
                <div
                  v-if="!loadMoreCargoDisabled"
                  v-observer="{ nextPage: nextPageCargo }"
                />
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="py-0 my-0" cols="12">
            <v-text-field
              outlined
              dense
              class="br-10"
              :label="$t('generate_ttn.appendix_to_the_description')"
              v-model="ttn.description"
              color="success"
            >
            </v-text-field>
          </v-col>
          <v-col class="py-0 my-0" cols="12">
            <v-textarea
              outlined
              dense
              class="br-10"
              rows="3"
              auto-grow
              :label="$t('generate_ttn.additional_information')"
              v-model="ttn.additionalInfo"
              hide-details
              color="success"
            >
              <template v-slot:append-outer>
                <div>
                  <v-btn icon small @click="getProductsTitles()">
                    <v-icon> mdi-package-variant-closed </v-icon>
                  </v-btn>
                  <v-btn icon small @click="ttn.additionalInfo = ''">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </div>
              </template>
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row class="my-0 py-0">
          <v-col cols="6">
            <v-btn
              color="error"
              block
              text
              class="w-100 br-10"
              @click="onCancel"
              :disabled="disabledActions"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              :color="'success'"
              block
              class="w-100 br-10"
              :disabled="disabledActions"
              @click="onConfirm"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.confirm") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import novaPochtaService from "@/services/request/novaPochta/novaPochtaService";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import notifications from "@/mixins/notifications";
import _ from "lodash";
import {
  validationForDepartment
} from "@/mixins/helpers";

export default {
  mixins: [validationMixin, notifications],
  data: () => ({
    searchCargoDescQuery: "",
    ttn: {
      additionalInfo: "",
      description: "",
      CargoDescription: "",
    },
    cargoList: [],
    loadMoreCargoDisabled: false,
    disabledActions: false,
    pagination: {
      page: 1,
      perPage: 50,
    },
    canSearch: false,
  }),
  props: {
    visible: {
      require: true,
    },
    inputAddress: {
      require: true,
    },
    radioServiceType: {
      require: true,
    },
    inputDelivery: {
      require: true,
    },
    inputCrmItem: {
      require: true,
    },
    chosenProducts: {
      require: true,
    },
    counterparty: {
      require: true,
    },
  },
  validations: {
    ttn: {
      CargoDescription: {
        required,
      },
    },
  },
  watch: {
    searchCargoDescQuery(val) {
      if (this.canSearch) {
        if (val || val == "") {
          this.pagination.page = 1;
          this.getCargoList(false);
        }
      }
      else {
        this.canSearch = true
      }
    },
  },
  mounted() {

    this.getCargoList(true);
    if (
      this.counterparty.uuid_ref_cargo_description !=
      "00000000-0000-0000-0000-000000000000"
    ) {
      this.cargoList.push({
        Ref: this.counterparty.uuid_ref_cargo_description,
        Description: this.counterparty.cargo_decscription,
      })
      this.ttn.CargoDescription = {
        Ref: this.counterparty.uuid_ref_cargo_description,
        Description: this.counterparty.cargo_decscription,
      };
      // this.searchCargoDescQuery = this.counterparty.cargo_decscription;
    }
    else {
      this.canSearch = true
    }
  },
  methods: {
    validationForDepartment,
    nextPageCargo() {
      this.pagination.page += 1;
      this.getCargoList(true);
    },
    getProductsTitles() {
      if (this.chosenProducts.length) {
        let info = "";
        this.chosenProducts.forEach((product, index) => {
          if (index != 0) {
            info += "; ";
          }
          info +=
            product.product_details.name + " - " + product.items_amount + "шт.";
        });


        this.ttn.additionalInfo = info;
      } else {
        this.setErrorNotification("Товарів немає.");
      }
    },
    getCargoList: _.debounce(async function (nextPage = false) {
      try {
        const params = {
          FindByString: this.searchCargoDescQuery,
          Page: this.pagination.page,
        };

        const res = await novaPochtaService.getCargoList(params);

        if (nextPage) {
          this.cargoList.push(...res);
        } else {
          this.cargoList = res;
        }
        this.loadMoreCargoDisabled = res.length < this.pagination.perPage;
      } catch (e) {
        console.log(e)
      }
    }, 400),
    onConfirm() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
          this.disabledActions = true
          const data = {
            additionalInfo: this.ttn.additionalInfo,
            description:
              this.ttn.CargoDescription.Description + "; " + this.ttn.description,
          };
          this.$emit("onConfirm", data);
      }
    },
    onCancel() {
      this.$emit("onCancel");
    },
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      },
    },
    CargoDescriptionError() {
      const errors = [];
      if (!this.$v.ttn.CargoDescription.$dirty) {
        return errors;
      }
      !this.$v.ttn.CargoDescription.required &&
        errors.push(this.$t("form.errors.CargoDescriptionRequired"));
      return errors;
    },
  },
};
</script>
