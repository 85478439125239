<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
    :fill="fill"
  >
    <path
      d="M4.12505 8.80123L1.37796 6.05415C1.06921 5.7454 0.570464 5.7454 0.261714
      6.05415C-0.0470361 6.3629 -0.0470361 6.86165 0.261714 7.1704L3.57088 10.4796C3.87963
      10.7883 4.37838 10.7883 4.68713 10.4796L12.063 2.10373C12.3717 1.79498 12.3717 1.29623
       12.063 0.987483C11.7542 0.678733 11.2555 0.678733 10.9467 0.987483L4.12505 8.80123Z"
    />
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'Check',
};
</script>

<style scoped>

</style>
