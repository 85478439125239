<template>
  <VDialog v-model="visibility" :width="500"  :transition="$dialogTransition">
    <VCard>
      <VCardTitle>
        {{ titleName }}
        <!-- {{ this.language.ADD_SMS_LAYOUT }} -->
      </VCardTitle>
      <VCardText>
        <VTextField outlined :label="this.language.NAME_SMS_LAYOUT" dense v-model="sms.name" maxlength="30"/>
        <v-textarea
          solo
          rows="4"
          row-height="10"
          counter="160"
          maxlength="160"
          :label="this.language.WRITE_MSG"
          v-model="sms.template"
          @keydown.enter.prevent
        >
          <template slot="append">
            <VMenu
              bottom
              origin="center center"
              transition="scale-transition"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <VIcon v-on="on" v-bind="attrs" color="primary">
                  mdi-information-outline
                </VIcon>
              </template>
              <VCard width="600">
                <VCardTitle>
                  <v-alert dense text type="info" class="w-100 mb-0">{{
                    this.language.USAGE_SMS_LAYOUT
                  }}</v-alert>
                </VCardTitle>
                <VCardText>
                  <VSimpleTable class="my-table supply" dense>
                    <thead>
                      <tr>
                        <th>Команда</th>
                        <th>Результат</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in templateValues" :key="item">
                        <td>{{$t(`sms.template.${item}`)}}: {{geItemHint(item)}}</td>
                        <td>{{$t(`sms.template.${item}`)}}: {{orderData[item] || '--'}}</td>
                      </tr>
                    </tbody>
                  </VSimpleTable>
                </VCardText>
              </VCard>
            </VMenu>
          </template>
        </v-textarea>
      </VCardText>
      <VCardActions>
        <VRow no-gutters justify="space-around">
          <VCol cols="5">
            <VBtn dark class="text-transform-none br-10" block @click="onCancel">
              {{ language.CANCEL }}
            </VBtn>
          </VCol>
          <VCol cols="5">
            <VBtn class="success-bg text-white text-transform-none br-10" block @click="onSuccess" :loading="loadingAdd" :disabled="loadingAdd">
              {{ btnName }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import EventBus from "../../events/EventBus";
import language from "../../mixins/language";
import SMSservice from "../../services/request/turboSMS/SMSservice";

const TYPE = {
  edit: "edit",
  add: "add"
};
export default {
  props: {
    visible: {
      require: true
    },
    orderData: {
      require: false
    },
    chosenLayout: {
      require: false
    },
    type: {
      require: false,
      default: TYPE.add
    }
  },
  data: () => ({
    templateValues: ["ttn","name","surname","phone","city_name","warehouse_name"],
    sms: {
      name: "",
      template: ""
    },
    loadingAdd:false
  }),
  mixins: [language],
  mounted() {

    if (this.type === TYPE.edit) {
      this.sms = {
        name: this.chosenLayout.name,
        template: this.chosenLayout.template,
        uuid: this.chosenLayout.uuid,
        uuid_user: this.chosenLayout.uuid_user
      };
    }
  },
  methods: {
    geItemHint(item){
      return `{${item}}`
    },
    onCancel() {
      this.$emit("close");
    },
    onSuccess() {

      if (this.type === TYPE.add) {
        this.onAddItem();
      } else {
        this.onUpdateItem();
      }
    },
    async onAddItem() {
      try {
        this.loadingAdd = true;
        const formData = new FormData();
        formData.append("name", this.sms.name);
        formData.append("template", this.sms.template);

        await SMSservice.addLayout(formData);
        this.sms = {};
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdd = false;
      }

      EventBus.$emit("add-template");
    },
    async onUpdateItem() {
      try {
        this.loadingAdd = true;
        const formData = new FormData();
        formData.append("name", this.sms.name);
        formData.append("uuid", this.sms.uuid);
        formData.append("template", this.sms.template);

        await SMSservice.updateLayout(formData);
        this.sms = {};
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingAdd = false;
      }

      EventBus.$emit("edit-template");
    }
  },
  computed: {

    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.sms = {};
        return this.$emit("close");
      }
    },
    titleName() {
      return this.$t(`sms.${TYPE[this.type]}.title`);
    },
    btnName() {
      return this.$t(`sms.${TYPE[this.type]}.btn.success`);
    }
  }
};
</script>

<style></style>
