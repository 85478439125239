<template>
  <div>
    <v-data-table
      fixed-header
      v-bind="{ ...$attrs }"
      :height="getTableHeight"
      :no-data-text="$t('table.noData')"
      :items="items"
      :loading="isLoadingItems"
      :loading-text="$t('table.loading')"
      :items-per-page="items.length"
      ref="table"
      :item-class="getRowClass"
      hide-default-footer
    >
      <!-- pass through scoped slots -->

      <template v-for="(_, scopedSlotName) in scopedSlots" v-slot:[scopedSlotName]="slotData">
        <slot :name="scopedSlotName" v-bind="slotData" />
      </template>

      <!-- pass through normal slots -->
      <template v-for="(_, slotName) in parentSlots" v-slot:[slotName]>
        <slot :name="slotName" />
      </template>

      <template v-slot:[`item.order`]="{ index }">
        <td>{{ index + 1 }}</td>
      </template>

      <template v-slot:[`body.append`]>
        <tr>

          <td :colspan="$attrs.headers.length + 1">
            <v-progress-linear
              v-if="isLoadingItems"
              indeterminate
              color="primary"
            ></v-progress-linear>
            <div
              v-observer="{ nextPage: nextPage }"
              class="observer"
              v-if="!loadMoreDisabled && !isLoadingItems"
            ></div>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="scroll-up__wrapper">
      <v-fab-transition>
        <VBtn
          v-if="isShowUpBtn"
          color="primary"
          small
          absolute
          bottom
          right
          fab
          @click="onScrollToTop"
          style="bottom: 0; right: 30px;"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </VBtn>
      </v-fab-transition>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import EventBus from "@/events/EventBus";
import nsDataTableMixin from "./ns-data-table-mixin";
import loader from "@/mixins/loader";

const DEFAULT_KEY = "uuid";
const DEFAULT_TABLE_OFFSET_TOP = 0

export default {
  name: "NsDataTable",
  mixins: [nsDataTableMixin, loader],
  props: {
    requestService: {
      type: Function,
      required: true
    },
    customHeight:{
      type: String,
      required: false
    },
    searchParams: {
      type: Object,
      required: false,
    },
    myTableOffsetTop: {
      type: Number,
      required: false,
      default:DEFAULT_TABLE_OFFSET_TOP
    },
    tableOffsetBottom: {
      type: Number,
      required: false,
      default:0
    }
  },
  mounted() {
    this.initTable(this.searchParams);
  },
  created() {
    EventBus.$on("on-modified", (data, key) => {
      this.modifyTable(data, key);
    });
    EventBus.$on("on-init", () => {
      this.loadMoreDisabled = true
      this.resetItems()
      this.onScrollToTop()
      this.resetPagination()
      this.initTable(this.searchParams)
    });
  },
  beforeDestroy() {
    EventBus.$off('on-modified"');
    EventBus.$off('on-init"');
  },
  watch: {
    searchParams: {
      deep: true,
      handler() {
          this.onApplyParams(this.searchParams);
        }
    }
  },
  methods: {
    getRowClass(item) {
      return item.class;
    },
    onApplyParams: _.debounce(function() {
      this.resetPagination()
      this.initTable(this.searchParams);
    }, 500),
    async initTable(params = {}, isUpdated = false) {
      try {
        this.isLoadingItems = true;
        let items = await this.requestService({
          offset: this.page * this.perPage,
          limit: this.perPage,
          ...params
        });
        if (!Array.isArray(items)) {
          items = [...items];
        }
        if (isUpdated) {
          this.items.push(...items);
        } else {
          this.items = items;
        }
        this.loadMoreDisabled = items.length < this.perPage;
      } catch (e) {
        console.error(e);
      } finally {
        this.isLoadingItems = false;
      }
    },

    modifyTable(data, key = DEFAULT_KEY) {
      if (!data) {
        console.error("Data is missing");
        this.reset();
        return;
      }
      if (
        Object.prototype.hasOwnProperty.call(data, key) &&
        this.items.every(item => Object.prototype.hasOwnProperty.call(item, key))
      ) {
        const index = this.items.findIndex(item => item[key] === data[key]);
        if (index !== -1) {
          this.addMissingKeys(data, this.items[index]);
          this.items.splice(index, 1, data);
          this.highlightRow(index);
        } else {
          console.error(`"${key}":"${data[key]}" is missing in items.`);
          this.reset();
        }
      } else {
        console.error(`Key ['${key}'] is missing in data or items.`);
        this.reset();
      }
    },
    addMissingKeys(data, specificItem) {
      const keys = Object.keys(specificItem);

      keys.forEach(key => {
        if (!Object.prototype.hasOwnProperty.call(data, key)) {
          data[key] = specificItem[key];
        }
      });

      return data;
    },

    highlightRow(index) {
      const table = this.$refs.table;
      if (table) {
        const row = table.$el.querySelector(
          `.v-data-table__wrapper tbody tr:nth-child(${index + 1})`
        );
        if (row) {
          row.style.backgroundColor = "rgba(174, 204, 246, 0.572)";
          setTimeout(() => {
            row.style.backgroundColor = "";
          }, 1000);
        }
      }
    },
    reset() {
      this.resetPage();
      this.resetItems();
      this.initTable(this.searchParams);
      this.resetPagination()
    },
    resetPagination() {
      this.page = 0;
      this.isShowUpBtn = false;
    },
    resetPage() {
      this.page = 0;
    },
    resetItems() {
      this.items = [];
    }
  },
  computed: {
    parentSlots() {
      return this.$slots;
    },
    scopedSlots() {
      return this.$scopedSlots;
    }
  },
  data() {
    return {
      items: [],
      windowHeight: document.documentElement.clientHeight,
      tableOffsetTop:DEFAULT_TABLE_OFFSET_TOP,
      isShowUpBtn: false,
      page: 0,
      isLoadingItems: false,
      loadMoreDisabled: false
    };
  }
};
</script>

<style>
tr {
  transition: 0.5s ease;
}
</style>
