<template>
  <VDialog v-model="visibility" max-width="400" :transition="$dialogTransition">
    <VCard>
      <VCardTitle class="headline" :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
        <v-alert text dense :type="typeAlert" class="w-100 v-custom-alert">
          <slot name="header"> </slot>
        </v-alert>
      </VCardTitle>
      <VCardText>
        <span class="text-left d-block" :class="{ 'text-caption': $vuetify.breakpoint.xs }">
          <slot name="description"></slot>
        </span>
      </VCardText>
      <VCardActions>
        <VRow class="my-0 py-0">
          <VCol cols="6">
            <VBtn
              color="error"
              block
              dark
              text
              class="w-100 br-10"
              @click="onCancel"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.cancel") }}
            </VBtn>
          </VCol>
          <VCol cols="6">
            <VBtn
              :color="typeAlert"
              block
              class="w-100 br-10"
              dark
              @click="onConfirm"
              :class="{ 'text-caption': $vuetify.breakpoint.xs }"
            >
              {{ $t("btn.confirm") }}
            </VBtn>
          </VCol>
        </VRow>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
export default {
  name: "ConfirmChangeState",
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    data: {
      required: false
    },
    typeAlert: {
      required: false,
      default: "success"
    }
  },
  methods: {
    onConfirm() {
      this.$emit("onConfirm", this.data);
    },
    onCancel() {
      this.$emit("onCancel");
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>

<style lang="scss">
.v-custom-alert {
  .v-alert__wrapper {
    i {
      margin-top: 4px;
    }
  }
}
</style>
