<template>
  <div class="search_wrapper">
    <div class="search-item" :style="showField ? 'width: 15rem' : ''">
      <!-- <v-scroll-x-reverse-transition> -->
      <!-- <v-autocomplete
          @keypress="validationForNumbers($event)"
          @paste="validationForNumbers($event)"
          v-if="showField"
          :color="$vuetify.theme.dark ? 'grey lighten-3' : 'black'"
          hide-details
          dense
          hide-selected
          :loading="loading"
          :items="Object.values(items)"
          item-text="ns_code"
          item-value="uuid"
          :label="$t('form.search')"
          :hide-no-data="!search"
          v-click-outside="clickOutside"
          :search-input.sync="search"
          @change="searchItem"
          v-model="searchQuery"
          cache-items
          clearable
          :return-object="true"
          @keyup.enter="searchItemKeyUp"
          inputRef={searchQuery}
        >
        <template v-slot:append-item>
    <div v-if="!loadMoreDisabled" v-observer="{nextPage:nextPage}" />
  </template>
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
               {{$t('table.noData')}}
              </v-list-item-title>
            </v-list-item>
          </template>
        </v-autocomplete> -->
      <v-scroll-x-reverse-transition>
        <VTextField
          v-if="showField"
          v-model="searchQuery"
          hide-details="auto"
          dense
          :color="$vuetify.theme.dark ? 'grey lighten-3' : 'black'"
          :label="$t('form.search')"
          @keyup.enter="searchItemKeyUp"
          @keypress="validationForNumbers($event)"
          @paste="validationForNumbers($event)"
          @input="onInputSearchField"
          inputRef="{searchQuery}"
          ref="searchField"
          clearable
          @click:clear="onClear"
          :loading="loading"
          :maxLength="16"
        />
      </v-scroll-x-reverse-transition>
      <v-scroll-y-reverse-transition hide-on-leave>
        <div class="px-2 position-relative" v-if="items && searchMenu && showField">
          <VList dense class="elevation-5 search-dropdown" v-if="items.length">
            <VListItem
              class="py-0"
              v-for="(item, index) in Object.values(items)"
              :key="index"
              @click="
                onSelectNsCode(item.ns_code);
                searchMenu = false;
              "
            >
              <VListItemTitle class="search-menu-item d-flex">
                <div
                  v-for="(char, index) in item.ns_code"
                  :key="index"
                  :style="getStyle(item.ns_code, index)"
                >
                  {{ char }}
                </div>
              </VListItemTitle>
            </VListItem>
          </VList>
          <VList v-else dense class="elevation-5 search-dropdown">
            <VListItem class="py-0">
              <VListItemTitle class="search-menu-item">
                {{ $t("table.noData") }}
              </VListItemTitle>
            </VListItem>
          </VList>
        </div>
      </v-scroll-y-reverse-transition>
    </div>
    <div>
      <VBtn icon @click="showField = !showField">
        <VIcon>mdi-magnify</VIcon>
      </VBtn>
    </div>
    <EditSupplyInDialog
      v-if="editInDialog"
      :visible="editInDialog"
      @close="editInDialog = false"
      :supply="deliveryData"
      @resetSearchField="resetSearchField"
    />
    <DialogWrapper
      v-if="editOutDialog"
      :visible="editOutDialog"
      @close="editOutDialog = false"
      :typeDialog="'edit'"
      settingDialog="supply"
    >
      <OrderDialog
        :uuidItem="uuidDel"
        :typeDialog="'edit'"
        settingDialog="supply"
        v-if="editOutDialog"
        @resetSearchField="resetSearchField"
      ></OrderDialog>
    </DialogWrapper>
    <ShowCellInfoDialog
      :visible="visible"
      @close="visible = false"
      v-if="visible"
      :selData="selData"
      @resetSearchField="resetSearchField"
    />
  </div>
</template>

<script>
import _ from "lodash";

import depotService from "../../services/request/depot/depotService";
import { validationForNumbers } from "@/mixins/helpers";
import DialogWrapper from "../orderDialog/DialogWrapper.vue";
import OrderDialog from "../orderDialog/OrderDialog.vue";
import ShowCellInfoDialog from "./ShowCellInfoDialog.vue";
import EditSupplyInDialog from "../supply/EditSupplyInDialog.vue";
import supplyService from "../../services/request/supply/supplyService";
import EventBus from "../../events/EventBus";

export default {
  components: {
    EditSupplyInDialog,
    ShowCellInfoDialog,
    OrderDialog,
    DialogWrapper
  },
  data: () => ({
    showField: false,
    loading: false,
    searchQuery: null,
    searchMenu: false,
    items: [],
    visible: false,
    search: null,
    valueLocal: "",
    pagination: {
      page: 0,
      perPage: 25
    },
    loadMoreDisabled: true,
    paginationParams: {},
    editOutDialog: false,
    editInDialog: false,
    uuidDel: "",
    selData: {},
    deliveryData: [],
    itemsIN: []
  }),
  watch: {
    search: _.debounce(function(val) {
      this.paginationParams = {
        ns_code: val
      };
      this.pagination.page = 1;
      this.getItems(false, this.paginationParams);
      this.valueLocal = val;
      val && val !== this.select;
    }, 500),
    searchQuery: {
      handler(e) {
        if(this?.searchQuery?.length === 16 && this?.searchQuery?.startsWith("88")) {
          this.onSelectNsCode(e);
        }
      }
    },
    showField(e) {
      if (this.items.length === 0) {
        // this.getItems();
      }
    }
  },
  mounted() {
    if (this.showField) {
      this.pagination.page = 1;
      this.paginationParams = {};
      this.getItems();
    }
  },

  methods: {
    validationForNumbers,
    resetSearchField() {
      this.$nextTick(() => {
        this.$refs.searchField.focus();
      });
      this.onReset();
      this.searchQuery = '';
    },
    getStyle(item, index) {
      const regex = new RegExp(this.searchQuery, "g");
      const matches = [...item.matchAll(regex)];
      const styles = {
        background: "orange",
        color: "black"
      };

      // eslint-disable-next-line no-restricted-syntax
      for (const match of matches) {
        if (index >= match.index && index < match.index + this.searchQuery.length) {
          return styles;
        }
      }
    },
    onInputSearchField: _.debounce(function() {
      if (this.searchQuery && this?.searchQuery?.length < 16) {
        this.paginationParams = {
          ns_code: this.searchQuery
        };
        this.pagination.page = 1;
        this.getItems(false, this.paginationParams);
        this.searchMenu = true;
        this.valueLocal = this.searchQuery;
      } else if (this.searchQuery && this?.searchQuery?.length === 16 && this?.searchQuery?.startsWith("88")) {
        this.paginationParams = {
          ns_code: this.searchQuery
        };
        this.pagination.page = 1;
        this.getItems(false, this.paginationParams);
        this.searchMenu = true;
        this.valueLocal = this.searchQuery;
      } else {
        this.onReset();
      }
    }, 500),
    onReset() {
      this.searchMenu = false;
      this.items = [];
    },
    onClear() {
      this.onReset();
    },
    async onSelectNsCode(elem) {
      const res = await depotService.checkNsCode(elem);
      this.searchQuery = elem;
      if(res.item) {
        this.$emit("showDialog", res.item.uuid);
      } else if(res?.delivery?.uuid && !res.item && res.delivery.type === 'out') {
        this.editOutDialog = true;
        this.uuidDel = res?.delivery?.uuid;
      } else if(res?.delivery?.uuid && !res.item && res.delivery.type === 'in') {
        if (res?.delivery?.uuid != "00000000-0000-0000-0000-000000000000") {
          await this.getSupplyList();
          this.itemsIN.forEach(item => {
            if (item.uuid === res.delivery.uuid) {
              this.deliveryData = item;
            }
          });
          this.editInDialog = true;
        }
      } else if(!res.delivery && !res.item) {
        this.visible = true;
        this.selData = res.cell;
      }
    },
    nextPage() {
      this.pagination.page += 1;
      this.getItems(true, this.paginationParams);
    },
    clickOutside() {
      // orderDialog();
      if (this.showField && !this.search) {
        this.showField = false;
        this.searchQuery = "";
      }
    },
    searchItem() {
      if (this.searchQuery) {
        this.$emit("showDialog", this.searchQuery.uuid);
      }
    },
    searchItemKeyUp() {
      if (this.valueLocal === this.searchQuery.ns_code) {
        this.$emit("showDialog", this.searchQuery.uuid);
      }
    },
    async getSupplyList() {
      try {
        const newItems = await supplyService.getSupplyList({
          type: "in"
        });
        this.itemsIN.push(...newItems.object)
      } catch (e) {
        this.loadingSupply = false;
      }
    },
    async getItems(nextPage = false, paramsArg = {}) {
      try {
        const params = {
          // offset: this.pagination.page * this.pagination.perPage,
          // limit: this.pagination.perPage,
          uselike: true,
          ...paramsArg
        };
        this.loading = true;
        const res = await depotService.getNsCodes(params);
        if (nextPage) {
          this.items.push(...res);
          this.$forceUpdate();
        } else {
          this.items = res;
        }
        this.loading = false;
        this.loadMoreDisabled = res.length < this.pagination.perPage;
      } catch (e) {
        this.loading = false;
        // orderDialog(e);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-dropdown {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  z-index: 999;
  right: 0;
}
.position-relative {
  position: relative;
}
.search_wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-item{
  transition: all .3s;
  width: 0px;
}
</style>
