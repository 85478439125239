<template>
  <VDialog v-model="visibility"  max-width="500px" content-class="cross__dialog"  :transition="$dialogTransition">
    <VCard :style="$vuetify.theme.dark ? 'background:#414141' : 'background:#fbfbfb'" max-height="600px">
      <div class="cross__inner">
        <VBtn
          fab
          small
          elevation="0"
          @click="resetField"
        >
          <v-icon>mdi-close</v-icon>
        </VBtn>
      </div>
      <VCardTitle>
        <VCol class="pa-3" :style="{ fontSize: '18px' }">
          {{$t('history.moreDetails')}}
        </VCol>
      </VCardTitle>
      <VCardText max-height="300">
        <v-data-table
          elevation="0"
          :headers="tableHeaders"
          :items="tableItems"
          hide-default-footer
          hide-default-header
          locale="uk"
          dense
          class="pa-3"
          :no-data-text="$t('table.noData')"
        ></v-data-table>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<script>
import langMixin from '@/mixins/language';

export default {
  mixins:[langMixin],
  data: () => ({
    changes: [],
    tmpChanges: []
  }),
  props: {
    visible: {
      required: true
    },
    selData: {
      require: true
    },
  },
  methods: {
    resetField() {
      this.visibility = false;
      this.$emit("resetSearchField");
    }
  },
  computed: {
    tableHeaders() {
      return [
        { value: 'column1'},
        { value: 'column2'},
      ];
    },
    tableItems() {
      return [
        {
          column1: this.$t("supply.cell"),
          column2: this?.selData?.name ? this?.selData?.name : "--"
        },
        {
          column1: this.$t("table.ns_code"),
          column2: this?.selData?.ns_code ? this.selData?.ns_code : "--"
        },
        {
          column1: this.$t("cell.parent_cell"),
          column2: this?.selData?.parent_details?.name ? this?.selData?.parent_details?.name : "--"
        },
        {
          column1: this.$t("history.sector"),
          column2: this?.selData?.sector_details?.name ? this?.selData?.sector_details?.name : "--"
        },
        {
          column1: this.$t("form.created_time"),
          column2: this?.time_created ? this.time_created : "--"
        },
        {
          column1: this.$t("form.changed_time"),
          column2: this?.time_changed ? this.time_changed : "--"
        },
      ];
    },
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
