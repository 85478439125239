/* eslint-disable import/no-cycle */
import requestService from '../requestService';

const prefix = '/contacts';

export default {
  async addJoinRequest(payload) {
    const response = await requestService.post(`${prefix}/add`,payload);
    return response?.data?.object;
  },
  async deleteJoinRequest(uuid) {
    // eslint-disable-next-line no-return-await
    return await requestService.delete(`${prefix}/delete/${uuid}`);
  },
  async updateJoinRequest(payload) {
    const response = await requestService.post(`${prefix}/update`,payload);
    return response?.data?.object;
  },
  async getJoinRequestList(payload) {
    const response = await requestService.get(`${prefix}/list`,payload);
    return response?.data?.object;
  },
  async getAmountNew() {
    const response = await requestService.get(`${prefix}/new/amount`);
    return response?.data?.message;
  },
  async getRequestMetrics() {
    const response = await requestService.get(`${prefix}/metrics`);
    return response?.data?.object;
  },
};
