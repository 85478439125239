<template>
  <VDialog
    v-model="visibility"
    max-width="1180px"
    content-class="cross__dialog order-dialog"
    :persistent="!changed"
    @click:outside="showConfirmDialog"
    scrollable
    @keydown.esc="showConfirmDialog"
    :transition="$dialogTransition"
  >
    <slot></slot>
  </VDialog>
</template>

<script>
import EventBus from "@/events/EventBus";

export default {
  props: {
    visible: Boolean,
    settingDialog: String,
    typeDialog: String,
    changed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showConfirmDialog() {
      EventBus.$emit("confirm-dialog", this.changed);
    }
  },
  created() {},
  beforeDestroy(){
    EventBus.$off("confirm-dialog");
    EventBus.$off("close-dialog");
  },
  mounted() {
    EventBus.$on("confirm-dialog", changed => {
      this.changed = changed;
    });
    EventBus.$on("close-dialog", () => {
      this.$emit("close");
    });
  },
  computed: {
    // eslint-disable-next-line vue/no-dupe-keys
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
        if (this.typeDialog === "add") {
          // this.dataReset();
        }
        // this.$destroy();
      }
    }
  }
};
</script>

<style lang="scss">
@import "orderDialog";
</style>
