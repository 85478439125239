import { mapGetters, mapActions } from 'vuex';
import * as getterTypes from '../store/modules/requestsAmount/types/getters';
import * as actionTypes from '../store/modules/requestsAmount/types/actions';

export default {
  methods: {
    ...mapActions('requestsAmount', {
      fetchAmount: actionTypes.FETCH_AMOUNT,
    }),
  },
  computed: {
    ...mapGetters('requestsAmount', {
      amount: getterTypes.GET_AMOUNT,
    }),
  },
};
