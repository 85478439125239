<template>
  <v-dialog 
    max-width="550px"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    v-model="visibility"
  >
    <v-card class="px-1 py-1" style="min-height: 360px">
      <div class="cross__inner">
        <v-btn fab small elevation="0" @click="onClose()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>
      <div class="text-center" v-if="loading">
        <v-progress-linear :size="50" color="primary" indeterminate absolute top></v-progress-linear>
      </div>
      <v-simple-table class="my-table" height="340px" v-else>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                <div class="header">
                  <span>{{ $t("table.status") }}</span>
                </div>
              </th>
              <th class="text-left">
                <div class="header">
                  <span>{{ $t("table.updatedData") }}</span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody name="fade" is="transition-group">
            <tr v-for="(item, index) in items" :key="index">
              <td>
                <div class="d-flex align-center justify-content-start ">
                  <StatusCircle
                    :status-name="item.id_state"
                    :type="'supply'"
                    :class="{ sm: !isLarge }"
                  />
                  <div class="elispsis text-subtitle-2">
                    {{ item?.[`${currentLanguage.key}_name`] || item?.name || '' }}
                  </div>
                </div>
              </td>
              <td class="text-center text-subtitle-2">
                {{ item.time_changed | createdAt(item.time_changed, currentLanguage)}}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-dialog>
</template>

<script>
import supplyService from "../../../../services/request/supply/supplyService";
import { mapGetters } from "vuex";
import StatusCircle from "@/components/move-list/StatusCircle.vue";
import SizeUi from "@/mixins/SizeUi";
import language from "@/mixins/language";

export default {
  data: () => ({
    items: [],
    loading: false
  }),
  mixins: [SizeUi, language],
  props: {
    visible: {
      required: true,
      type: Boolean
    },
    uuid: {
      required: true,
      type: String
    }
  },
  components: {
    StatusCircle
  },
  methods: {
    async getItems() {
      try {
        this.loading = true;
        const newItems = await supplyService.deliveriesStateHistory(this.uuid);
        this.items = newItems;
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
    onClose() {
      this.visibility = false;
    },
  },
  mounted() {
    this.getItems();
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        return this.$emit("close");
      }
    },
    ...mapGetters(["currentLanguage"])
  },
  
};
</script>
