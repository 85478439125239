export default {
  props: {
    fill: {
      default: '#757575',
    },
    width: {
      default: 24,
    },
    height: {
      default: 24,
    },
    viewBoxValue: {
      required: false,
    },
  },
  computed: {
    viewBox() {
      if (this.viewBoxValue) return this.viewBoxValue;
      return `0 0 ${this.width} ${this.height}`;
    },
  },
};
