import * as mutationTypes from './types/mutations';
import { DEFAULT_STORAGE } from "@/mixins/constants";

export default {
  [mutationTypes.SET_START_TIME]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("ns-data") !== null) {
      data = JSON.parse(localStorage.getItem("ns-data"));
    }
    data.ui.startTime = value;
    localStorage.setItem("ns-data", JSON.stringify(data));

    state.startTime = value;
  },
  [mutationTypes.SET_PAGE_LOAD_TIME]: (state, value) => {
    let data = DEFAULT_STORAGE;
    if (localStorage.getItem("ns-data") !== null) {
      data = JSON.parse(localStorage.getItem("ns-data"));
    }
    data.ui.pageLoadTime = value;
    localStorage.setItem("ns-data", JSON.stringify(data));

    state.pageLoadTime = value;
  },
};
