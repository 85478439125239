import * as mutations from './types/mutations';

export default {
  [mutations.SET_SUPPLY_PRODUCT]: (state, product) => {
    const productFind = state.products.find((item) => item.uuid === product.uuid);
    if (!productFind) {
      state.products.push(product);
    }
  },
  [mutations.REMOVE_SUPPLY_PRODUCT]: (state, uuid) => {
    state.products = state.products.filter((product) => product.uuid !== uuid);
  },
  [mutations.CLEAR_CART]: (state) => {
    state.products = [];
    state.visible = false;
  },
  [mutations.OPEN_CART_DIALOG]: (state) => {
    state.visible = true;
  },
  [mutations.CLOSE_CART_DIALOG]: (state) => {
    state.visible = false;
  },
};
