import _ from "lodash";

const DEFAULT_TABLE_OFFSET_TOP = 0

export default {
  data() {
    return {

    };
  },
  mounted() {
    this.getTableOffsetTop()
    this.$nextTick(() => {
      const el = document.querySelector(".v-data-table__wrapper");
      el.addEventListener("scroll", this.handleScroll);
    });
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    const el = document.querySelector(".v-data-table__wrapper");
    if(el){
      el.removeEventListener("scroll", this.handleScroll);
    }
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleScroll(e) {
      if (document.querySelector(".v-data-table__wrapper").scrollTop > 750) {
        this.isShowUpBtn = true;
      } else {
        this.isShowUpBtn = false;
      }
    },
    getTableOffsetTop(){
      if(this.mytableOffsetTop){
        this.tableOffsetTop = this.mytableOffsetTop
      }else{
        const table = document.querySelector(".v-data-table__wrapper")
        this.tableOffsetTop = table ? table.getBoundingClientRect().top + 20 || DEFAULT_TABLE_OFFSET_TOP : DEFAULT_TABLE_OFFSET_TOP
      }
    },
    handleResize(e) {
      this.windowHeight = e.target.innerHeight;
    },
    getWindowHeight: _.debounce(function() {
      this.windowHeight = document.documentElement.clientHeight;
    }, 500),
    onScrollToTop() {
      this.$scrollTop({}, document.querySelector(".v-data-table__wrapper"));
    },
    async nextPage() {
      this.page += 1;
      this.isLoadingItems = true;
      await this.initTable(this.searchParams, true);
      this.isLoadingItems = false;
    }
  },
  computed: {
    perPage() {
      const amount =  Math.ceil((this.windowHeight - this.tableOffsetTop) / this.rowHeight)
      if(amount <= 0 ) {
        return 1
      }
      return amount
    },
    rowHeight() {
      if (this.isLarge) {
        return 48;
      } else {
        return 35;
      }
    },

    getTableHeight() {
      if(this.customHeight){
        return this.customHeight
      }
      return this.windowHeight - this.tableOffsetTop - this.tableOffsetBottom + "px";
    }
  }
};
