import requestService from '../requestService';

const prefix = '/crm';

export default {
  async getListForms() {
    const response = await requestService.get(`${prefix}/boards/forms/list`);
    return response?.data?.object;
  },
  async addItemForm(payload) {
    const response = await requestService.post(`${prefix}/boards/forms/add`, payload);
    return response?.data?.object;
  },
  async updateItemForm(payload) {
    const response = await requestService.post(`${prefix}/boards/forms/update`, payload);
    return response?.data?.object;
  },
  async delayItemForm(payload) {
    const response = await requestService.delete(`${prefix}/boards/forms/delete/${payload}`);
    return response?.data;
  },
  async getProductList(uuid, payload) {
    const response = await requestService.get(`${prefix}/boards/forms/items/list?uuid_form=${uuid}`, payload);
    return response?.data?.object;
  },
  async deleteProductItem(payload) {
    const response = await requestService.delete(`${prefix}/boards/forms/items/delete/${payload}`);
    return response?.data;
  },
  async addProduct(payload){
    const response = await requestService.post(`${prefix}/boards/forms/items/add`, payload)
    return response?.data?.object;
  },
  async updateProduct(payload){
    const response = await requestService.post(`${prefix}/boards/forms/items/update`, payload)
    return response?.data?.object;
  },

  // MARKET
  async getFormByUUID(uuid) {
    const response = await requestService.get(`${prefix}/boards/forms/info/${uuid}`, {}, {}, false);
    return response?.data?.object;
  },
  async initCart(payload){
    const response = await requestService.post(`${prefix}/boards/forms/cart/init`,payload)
    return response?.data?.object;
  },
  async cartInfo(uuid){
    const response = await requestService.get(`${prefix}/boards/forms/cart/info/${uuid}`)
    return response?.data?.object;
  },
  async clearCart(uuid){
    const response = await requestService.delete(`${prefix}/boards/forms/cart/clear/${uuid}`)
    return response?.data?.object;
  },
  async addItemInCart(payload){
    const response = await requestService.post(`${prefix}/boards/forms/cart/items/add`,payload)
    return response?.data?.object;
  },
  async getCartProductList(uuid){
    const response = await requestService.get(`${prefix}/boards/forms/cart/items/list/${uuid}`)
    return response?.data?.object;
  },
  async deleteItemFromCart(uuid){
    const response = await requestService.delete(`${prefix}/boards/forms/cart/items/delete/${uuid}`)
    return response?.data?.object;
  },
  async updateAmountItemInCart(payload){
    const response = await requestService.post(`${prefix}/boards/forms/cart/items/amount`,payload)
    return response?.data?.object;
  },
  async submitOrder(uuid,payload,header){
    const response = await requestService.post(`${prefix}/boards/forms/cart/submit/${uuid}`, payload)
    return response.data;
  },
  async AddOptions(payload){
    const response = await requestService.post(`${prefix}/boards/forms/items/options/add`, payload)
    return response?.data?.object;
  },
  async getFormItemOptions(payload){
    const response = await requestService.get(`${prefix}/boards/forms/items/options/list`, payload)
    return response?.data?.object;
  },
  async deleteFormItemOption(uuid,payload){
    const response = await requestService.delete(`${prefix}/boards/forms/items/options/delete/${uuid}`, payload)
    return response?.data?.object;
  },
  async updateOptionName(payload){
    const response = await requestService.post(`${prefix}/boards/forms/items/options/update`, payload)
    return response?.data?.object;
  },
  async clearItemsOptions(uuid){
    const response = await requestService.delete(`${prefix}/boards/forms/items/delete/options/${uuid}`)
    return response?.data?.object;
  },
  async setOptionDefault(uuid){
    const response = await requestService.patch(`${prefix}/boards/forms/items/options/default/${uuid}`)
    return response?.data?.object;
  },

  // Categories
  async addCategory(payload){
    const response = await requestService.post(`${prefix}/boards/forms/items/categories/add`, payload)
    return response?.data?.object;
  },
  async getCategories(payload){
    const response = await requestService.get(`${prefix}/boards/forms/items/categories/list`, payload)
    return response?.data?.object;
  },
  async updateCategory(payload){
    const response = await requestService.post(`${prefix}/boards/forms/items/categories/update`,payload)
    return response?.data?.object;
  },
  async deleteCategory(uuid){
    const response = await requestService.delete(`${prefix}/boards/forms/items/categories/delete/${uuid}`)
    return response?.data?.object;
  },
  // Cashier
  async addCashier(payload){
    const response = await requestService.post(`${prefix}/boards/cashiers/add`, payload)
    return response?.data?.object;
  },
  async getCashiers(payload) {
    const response = await requestService.get(`${prefix}/boards/cashiers/list`, payload);
    return response?.data?.object;
  },
  async deleteCashier(payload) {
    const response = await requestService.delete(`${prefix}/boards/cashiers/delete/${payload}`);
    return response?.data?.object;
  },
  async updateCashier(payload){
    const response = await requestService.post(`${prefix}/boards/cashiers/update`, payload)
    return response?.data?.object;
  },
  async setDefaultCashier(uuid) {
    const response = await requestService.patch(`${prefix}/boards/cashiers/default/${uuid}`);
    return response?.data?.object;
  },
  async generateReceipt(payload){
    const response = await requestService.post(`${prefix}/orders/receipt/generate`, payload, {}, false);
    return response?.data?.object;
  },
  async resetReceipt(payload) {
    const response = await requestService.post(`${prefix}/orders/receipt/reset/${payload}`);
    return response?.data?.object;
  }
};
