<template>
  <VMenu :close-on-content-click="false"
         :offset-y="true"
         :offset-x="true"
         transition="slide-x-transition"
         min-width="450"
         max-height="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <VBtn
              text
              :icon="$vuetify.breakpoint.mobile"
              class="px-md-2 mx-1"
              :outlined="isOutlined"
              :small="$vuetify.breakpoint.mobile"
              v-bind="attrs"
              v-on="on"
            >
              <VIcon :color="wifiColor">mdi-wifi</VIcon>
            </VBtn>
          </template>
          <span>{{ rtt }} ms</span>
        </v-tooltip>
        </span>
    </template>
    <VCard style="width: 450px; height: 335px;">
      <VCardText class="pa-0">
        <StatsChart
          @resetChart="timeData = []"
          :chartData="timeData"
          :type="typeChart"
        />
      </VCardText>
    </VCard>
  </VMenu>
</template>

<script>
import Avatar from "./Avatar.vue";
import StatsChart from "../crm/stats/StatsChart.vue";

export default {
  name: "IConnection",
  components: {StatsChart, Avatar},
  props: {
    isOutlined: {
      require: false,
      default: true,
    }
  },
  data() {
    return {
      isConnected: true,
      rtt: 0,
      wifiColor: "black",
      intervalId: null,
      timeData: [],
      typeChart: "IConnection",
    };
  },
  mounted() {
    this.checkInternetConnection();
    this.intervalId = setInterval(this.checkInternetConnection, 5000);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    checkInternetConnection() {
      if (navigator.onLine) {
        this.isConnected = true;
        this.pingGoogle();
      } else {
        this.isConnected = false;
      }
    },
    pingGoogle() {
      const startTime = performance.now();
      fetch("/", {method: "HEAD", cache: "no-store"})
        .then((response) => {
          const endTime = performance.now();
          this.rtt = Math.round(endTime - startTime);
          this.countLoadTime();
          this.getConnectionQuality(this.rtt);
        })
        .catch(() => {
          this.isConnected = false;
        });
    },
    countLoadTime() {
      if (this.timeData.length < 20) {
        this.timeData.push(this.rtt);
      } else if (this.timeData.length === 20) {
        this.timeData.splice(0, 1);
        this.timeData.push(this.rtt);
      }

    },
    getConnectionQuality(rtt) {
      if (rtt < 200) {
        this.wifiColor = "success";
      } else if (rtt < 500) {
        this.wifiColor = "warning";
      } else {
        this.wifiColor = "error";
      }
    },
  },
  watch: {
    isConnected: {
      deep: true,
      handler() {
        this.isConnected ? '' : (this.wifiColor = "black", this.rtt = 0)
      }
    }
  }
};
</script>

<style scoped>
</style>
