<template>
  <div>
    <VSheet width="735" flat>
      <div class="chat__wrapper" :class="{ sm: $vuetify.breakpoint.smAndDown }">
        <div class="chat__content" :class="{ sm: $vuetify.breakpoint.smAndDown }">
          <div
            class="chat__msgcontent"
            ref="msgContent"
            v-if="!loading"
            :class="{ sm: $vuetify.breakpoint.smAndDown }"
          >
            <div
              v-for="(group, date) in groupedMessages"
              :key="date"
            >
              <div class="date-marker">
                <div class="date-marker__content">
                  {{ date }}
                </div>
              </div>
              <div
                v-for="item in group"
                :key="item.uuids"
                class="chat__msg"
                :class="{ 'not-client': getUserProperty('uuid') !== item.uuid_user, 'history_msg': item.user_state_name || item.name }"
              >
                <v-tooltip v-if="item.message" top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="avatar"
                      v-if="getUserProperty('uuid') !== item.uuid_user"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <img :src="item.user_details.path_img" alt="" />
                    </div>
                  </template>
                  <span>{{ `${item.user_details.name} ${item.user_details.surname}` }}</span>
                </v-tooltip>
                <div v-if="item.message" class="chat__msg-content" :class="{ sm: $vuetify.breakpoint.smAndDown }">
                  <p class="text">{{ item.message }}</p>
                  <i class="time">{{ moment(item.time).format("LT") }}</i>
                </div>
                <div v-if="item.user_state_name || item.uk_name" :style="item.user_state_color ? `background:${item.user_state_color}` : ''" class="chat__msg-content" :class="{ sm: $vuetify.breakpoint.smAndDown }">
                  <p v-if="item.user_state_name && item.uk_name" class="text">{{ `${item.user_state_name} (${item?.[`${currentLanguage.key}_name`] || item?.name })` }}</p>
                  <p v-if="!item.user_state_name && item.uk_name" class="text">{{ ` (${item?.[`${currentLanguage.key}_name`] || item?.name})` }}</p>
                  <p v-if="item.user_state_name && !item.uk_name" class="text">{{ item.user_state_name }}</p>
                  <i class="time">{{ moment(item.time).format("LT") }}</i>
                </div>
                <v-tooltip v-if="item.user_state_name || item.name" right>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="avatar-2"
                      v-if="item.user_state_name || item.name"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <img :src="item.user_details.path_img" alt="" />
                    </div>
                  </template>
                  <span>{{ `${item.user_details.name} ${item.user_details.surname}` }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
          <div
            v-else
            class="w-100 bg-white d-flex justify-content-center align-center chat__content"
          >
            <transition name="fade">
              <div>
                <VRow no-gutters align="center" justify="center">
                  <VProgressCircular
                    :size="50"
                    :width="5"
                    color="success"
                    indeterminate
                  ></VProgressCircular>
                </VRow>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </VSheet>
  </div>
</template>

<script>
import supplyService from "../../services/request/supply/supplyService";
import notifications from "../../mixins/notifications";
import user from "../../mixins/user";
import moment from "moment";
import Vue from "vue";
import {mapGetters} from "vuex";
import language from "@/mixins/language";
import EventBus from "@/events/EventBus";

Vue.prototype.moment = moment;

export default {
  mixins: [notifications, user, language],
  props: {
    item: {
      require: true,
      type: Object
    },
    tab: {
      require: false,
      type: String
    },
    isSendMsg: {
      type: Boolean
    }
  },
  data: () => ({
    inputMessage: "",
    items: [],
    historyItems: [],
    allItems: [],
    loading: false
  }),
  async mounted() {
    //
    await this.getMsg();
    this.scrollToEnd();


    this.intervalFetchData = setInterval(async () => {
      this.getMsg();
    }, 5000);
  },
  destroyed() {
    clearInterval(this.intervalFetchData);
    this.intervalFetchData = null;
  },
  beforeDestroy() {
    clearInterval(this.intervalFetchData);
    this.intervalFetchData = null;
  },
  computed: {
    ...mapGetters([
      'currentLanguage'
    ]),
    groupedMessages() {
      return this.groupMessagesByDate(this.allItems);
    }
  },
  methods: {
    keyup(event) {
      if ((event.keyCode == 91 && event.composed) || (event.keyCode == 86 && event.ctrlKey)) {
        //
        // this.onSendMsg();
      }
    },
    scrollToEnd() {
      const container = this.$el.querySelector(".chat__msgcontent");
      container.scrollTop = container.scrollHeight + 16;
    },
    async getMsg() {
      try {
        const newItem = await supplyService.chatLog(this.item.uuid);
        this.items = newItem.reverse();
        const historyItem = await supplyService.chatHistory(this.item.uuid);
        this.historyItems = historyItem;
        this.allItems = this.items.concat(this.historyItems);
        this.allItems.sort((a, b) => {
          const timeA = new Date(a.time);
          const timeB = new Date(b.time);

          return timeA - timeB;
        });
      } catch (e) {
        console.log(e)
      }
    },
    groupMessagesByDate(items) {
      const groupedMessages = {};
      items.forEach(item => {
        const date = moment(item.time).format("D MMMM YYYY");
        if (!groupedMessages[date]) {
          groupedMessages[date] = [];
        }
        item.grouped = true; // Mark item as grouped
        groupedMessages[date].push(item);
      });
      return groupedMessages;
    }
  },
  watch: {
    isSendMsg: {
      async handler() {
        this.scrollToEnd();
        await this.getMsg();
        this.scrollToEnd();

      }
    }
  }
};
</script>

<style lang="scss">
.chat {
  &__wrapper {
    border: 1px solid #e3e3e3;
    border-radius: 10px;
  height: auto;
    min-height: 230px;
    &.sm {
      border-radius: 5px
    }
    &__title {
      &-time {
        height: 48px;
        font-weight: 500;
      }
    }
  }
  &__content {
    max-width: 100%;
    margin: auto;
    height: auto;
    min-height: inherit;
    position: relative;
    padding-top: 10px;
    display: flex;
    flex-direction: column-reverse;
    &.sm {
      max-width: 100%;
    }
    .avatar {
      width: 30px;
      height: 30px;
      margin-right: 5px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    .avatar-2 {
      width: 20px;
      height: 20px;
      margin-left: 5px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }
  &__msgcontent {
    max-height: 400px;
    min-height: 400px;
    height: auto;
    overflow-y: scroll;
    scroll-behavior: auto;

    .date-marker {
      display: flex;
      justify-content: center;
      align-items: center;


      &__content {
        max-width: 30%;
      }

    }

    &::-webkit-scrollbar {
      width: 8px !important;
      height: 12px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(113, 113, 113,.3);
      border: thin solid rgb(245, 245, 244);
      border-radius: 9em;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: darken(rgba(113, 113, 113,.5), 4);
    }
    &.sm {
      padding: 0 10px;
    }
  }
  &__footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 1000;
  }
  &__msg {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &:last-child {
      .chat__msg-content {
        border-radius: 10px 15px 0 15px;
      }
    }
    &-content {
      flex: 0 1 auto;
      width: auto;
      padding: 8px 8px 6px;
      background: #5d90c1;
      border-radius: 10px 15px 10px 15px;
      display: flex;
      color: #ffff;
      max-width: 40%;
      &.sm {
        max-width: 60%;
      }

      .text {
        margin: 0;
        word-break: break-word;
        line-height: 1.3125;
        text-align: initial;
        display: flow-root;
        unicode-bidi: plaintext;
        font-weight: 500;
      }
      .time {
        position: relative;
        bottom: auto !important;
        margin-left: 0.4375rem;
        margin-right: 0.2rem;
        font-size: 12px;
      }
    }
    &.not-client {
      &.chat__msg {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .chat__msg-content {
        display: flex;
        background: #f4f5f3;
        border-radius: 15px 10px 15px 10px;
        color: #000000;

        .text {
          font-weight: 400;
        }
      }
    }
    &.history_msg {
      border-bottom: 2px solid #F8F8FF;
      &.chat__msg {
        margin-bottom: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
      }

      .chat__msg-content {
        display: flex;
        background: #f4f5f3;
        border-radius: 15px 10px 15px 10px;
        color: #000000;

        .text {
          font-weight: 400;
        }
      }
    }
  }
}
.telegram-textarea {
  display: flex;
  padding: 0 1px 1px 1px;
  position: relative;
  .v-text-field input {
    padding-top: 0.2rem !important;
    padding-bottom: 0.2rem !important;
  }
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
      border-radius: 10px !important;
      padding-right: 7% !important;
      padding-left: 2% !important;
      font-weight: 400 !important;
    }
  .send-btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}
.send-btn {
  transition: 0.4s ease;
  &:hover {
    i {
      color: #808080 !important;
    }
  }
}
</style>
