<template>
  <v-dialog v-model="visibility" max-width="600px" persistent>
    <v-card>
      <v-card-title class="headline" :class="{ 'px-0': $vuetify.breakpoint.smAndDown }">
        <v-alert text dense type="error" class="w-100">
          500 Error
        </v-alert>
      </v-card-title>
      <v-card-text>
        <h3 class="text-h5">
          {{ $t("error.dialog.500.title") }}
        </h3>
        <div>
          {{ $t("error.dialog.500.description") }}
        </div>

        <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

        <v-row align="center" no-gutters>
          <v-col class="grow"> </v-col>
          <v-spacer></v-spacer>
          <v-col class="shrink">
            <v-btn outlined @click="onOk">
              {{ $t("btn.ok") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import * as mutations from "@/store/modules/auth/types/mutations";

export default {
  name: "CreateAddressDialog",
  props: ["visible"],
  methods: {
    onOk() {
      this.$store.commit(`auth/${mutations.SET_SHOW_500_ERROR_DIALOG}`, false);
    }
  },
  computed: {
    visibility: {
      get() {
        return this.visible;
      },
      set() {
        this.$emit("close");
      }
    }
  }
};
</script>

<style></style>
