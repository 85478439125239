<template>
  <span class="mr-1" :class="statusClass"></span>
</template>

<script>
export default {
  name: "StatusCircle",
  props: {
    statusName: {
      required: false,
      type: String
    },
    type: {
      require: false
    }
  },
  watch: {
    statusName: {
      deep: true,
      handler(e) {
      }
    }
  },
  mounted() {
  },
  computed: {
    // eslint-disable-next-line consistent-return,vue/return-in-computed-property
    statusClass() {
      if (this.type === "supply") {
        switch (this.statusName) {
          case "210":
            return "status-210";
          case "250":
            return "status-250";
          case "401":
            return "status-401";
          case "410":
            return "status-410";
          case "411":
            return "status-411";
          case "412":
            return "status-412";
          case "420":
            return "status-420";
          case "430":
            return "status-430";
          case "440":
            return "status-440";
          case "450":
            return "status-450";
          case "490":
            return "status-490";
          case "321":
            return "status-321";
          case "351":
            return "status-351";
          case "301":
            return "status-301";
          case "330":
            return "status-330";
          case "311":
            return "status-311";
          case "320":
            return "status-320";
          case "312":
            return "status-312";
          case "310":
            return "status-310";
          case "350":
            return "status-350";
          case "360":
            return "status-360";
          case "308":
            return "status-308";
          case "302":
            return "status-302";
          case "390":
            return "status-390";
          case "322":
            return "status-322";
          case "3000":
            return "status-3000";
          case "3010":
            return "status-3010";
          case "3020":
            return "status-3020";
          case "3030":
            return "status-3030";
          case "3040":
            return "status-3040";
          case "1300":
            return "status-1300";
          case "1350":
            return "status-1350";
          case "1400":
            return "status-1300";
          case "1450":
            return "status-1350";
          default:
            return "status-400";
        }
      } else {
        switch (this.statusName) {
          case "900":
            return "status-new";
          case "901":
            return "status-progress";
          case "902":
            return "status-on-hold";
          case "903":
            return "status-issue";
          case "904":
            return "status-done";
          default:
            return "";
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
span {
  min-width: 11px;
  min-height: 11px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  transition: 0.2s linear;

  &.sm {
    min-width: 10px;
    min-height: 10px;
  }
}
.status {
  &-new {
    background: #62ca4c;
  }
  &-progress {
    background: #f4eb00;
  }
  &-done {
    background: #9049d8;
  }
  &-on-hold {
    background: #df7f52;
  }
  &-issue {
    background: #d84949;
  }
}

.status {
  // Підтвержена клієнтом
  &-400,&-200,&-3000{
    background: #4894d9;
  }
  &-401,
  &-210,
  &-321,
  &-351,
  &-3030,
  &-1350,
  &-1450 {
    color: #65bc54 !important;
    background-color: rgba(#6dac60, 1) !important;
  }
  // Готово до упаковки
  &-410,
  &-301,
  &-330 {
    color: #6f56aa !important;
    background-color: rgba(#6f56aa, 1) !important;
  }
  // Упаковка
  &-411,
  &-311,
  &-320,
  &-3010 {
    color: #caab62 !important;
    background-color: rgba(#caab62, 1) !important;
  }
  // Упаковка завершена
  &-412,
  &-312,
  &-3020 {
    color: #ca6285 !important;
    background-color: rgba(#ca6285, 1) !important;
  }
  // Готово до Відправки
  &-420,
  &-310 {
    color: #ae62ca !important;
    background-color: rgba(#ae62ca, 1) !important;
  }
  // Відправлено Клієнту
  &-360 {
    color: #5fc0a8 !important;
    background-color: rgba(#5fc0a8, 1) !important;
  }

  // Прийом на склад
  &-350 {
    color: #e06b04 !important;
    background-color: rgba(#e06b04, 1) !important;
  }
  &-430{
    color: #5fc0a8 !important;
    background-color: rgba(#389773, 1) !important;
  }
  // Відмова
  &-440,
  &-250,
  &-308,
  &-1300,
  &-1400 {
    color: #dd2e2e !important;
    background-color: rgba(#dd2e2e, 1) !important;
  }
  // Доставлено Клієнту"
  &-450 {
    color: #5fc0a8 !important;
    background-color: rgba(#5fc0a8, 1) !important;
  }

  // Доставлено на склад
  &-302 {
    color: #e6d735 !important;
    background-color: rgba(#e6d735, 1) !important;
  }
  // Дефектна Відправка
  &-490,
  &-390,
  &-322,
  &-3040 {
    color: #c05f5f !important;
    background-color: rgba(#c05f5f, 1) !important;
  }
  //
}
</style>
