import * as actionTypes from './types/actions';
import * as mutationTypes from './types/mutations';

export default {
  [actionTypes.SET_IS_DARK]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_IS_DARK, value);
  },
  [actionTypes.SET_CHOSEN_THEME]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_CHOSEN_THEME, value);
  },
};
