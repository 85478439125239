<template>
  <div class="w-100">
    <v-row align="center" justify="space-between" no-gutters>
      <v-col cols="12" md="5" v-if="typeDialog === 'add' && !activeProduct">
        <div
          class="chips-container"
          :class="{ 'chips-container-mobile ml-0': $vuetify.breakpoint.smAndDown }"
        >
          <div v-if="!loading && loadingTags">
            <v-progress-circular indeterminate size="10" width="1" color="primary" />
          </div>
          <v-chip-group
            v-else
            class="chips-container"
            :class="{ 'chips-container-mobile': $vuetify.breakpoint.smAndDown }"
            :show-arrows="true"
            v-model="selectedTag"
            hide-details
            v-click-outside="clickOutside"
            :mandatory="selectedTag ? true : false"
          >
            <v-chip v-for="(tag, index) in tags" :key="index" small outlined :value="tag.uuid">
              <span v-if="!inputEdit || selectedTag != tag.uuid">{{ tag.tag }}</span>
              <VBtn
                @click="
                  editedTag = { ...tag };
                  inputEdit = true;
                "
                v-if="selectedTag == tag.uuid && !inputEdit"
                class="ml-1"
                x-small
                icon
              >
                <VIcon small>mdi-pencil</VIcon>
              </VBtn>
              <VBtn
                @click="
                  deletedTag = tag.uuid;
                  confirmDelete = true;
                "
                v-if="selectedTag == tag.uuid && !inputEdit"
                x-small
                icon
              >
                <VIcon>mdi-close</VIcon>
              </VBtn>
              <v-text-field
                class="py-0"
                autocomplete="new-password"
                append-icon="mdi-close"
                @click:append="
                  editedTag = null;
                  inputEdit = false;
                "
                @keypress="validInput"
                autofocus
                v-if="inputEdit && selectedTag == tag.uuid"
                style="width: 120px;"
                @keydown.enter="updateTagFromArray"
                dense
                v-model="editedTag.tag"
                hide-details
                solo
              >
              </v-text-field>
            </v-chip>
          </v-chip-group>
        </div>
      </v-col>
      <v-col cols="12" md="5" v-else>
        <v-tooltip bottom v-if="!addTagProccess && typeDialog === 'add' && !activeProduct">
          <template v-slot:activator="{ on, attrs }">
            <VBtn
              v-on="on"
              v-bind="attrs"
              @focusin="
                selectedTag = null;
                addTagProccess = true;
              "
              icon
              color="success"
              small
            >
              <VIcon color="success" size="20">mdi-plus-circle-outline </VIcon>
            </VBtn>
          </template>
          {{ $t("crm.add_tag") }}
        </v-tooltip>
        <v-combobox
          class="ml-4 py-0"
          autocomplete="new-password"
          append-icon="mdi-close"
          :items="tagsFullList"
          @click:append="
            newTag = '';
            addTagProccess = false;
          "
          @change="addTagToArray"
          @keypress="validInput"
          autofocus
          v-if="addTagProccess && typeDialog === 'add' && !activeProduct"
          style="width: 120px;"
          dense
          v-model="newTag"
          hide-details
          solo
        >
        </v-combobox>
        <div v-if="typeDialog !== 'add' || activeProduct" class="d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <VIcon
                v-bind="attrs"
                v-on="on"
                class="mr-1"
                :color="item.type === 'out' ? 'green' : 'yellow darken-1'"
              >
                {{ item.type === "out" ? "mdi-truck-delivery" : "mdi-alarm" }}
              </VIcon>
            </template>
            <span>{{
              item.type === "out" ? $t("orderDialog.send") : $t("orderDialog.waiting")
            }}</span>
          </v-tooltip>
          <v-card-subtitle class="py-0 px-0 d-flex" v-if="typeDialog !== 'add' || activeProduct">
            <div
              class="justify-center align-center d-flex"
              justify="center"
              justify-md="start"
              align="center"
              no-gutters
              style="min-height: 30px; width: 100%"
            >
              <div v-if="loading">
                <v-progress-circular indeterminate size="10" width="1" color="primary" />
              </div>
              <div v-else style="min-width: 105px; padding-right: 10px">
                {{ "#" }}
                <span class="copyTxt ml-1" @click="copyToClipboard(item.number)">{{
                  item.number
                }}</span>
              </div>
              <div
                class="chips-container"
                :class="{ 'chips-container-mobile ml-0': $vuetify.breakpoint.smAndDown }"
              >
                <div v-if="!loading && loadingTags">
                  <v-progress-circular indeterminate size="10" width="1" color="primary" />
                </div>
                <v-chip-group
                  v-else
                  class="chips-container"
                  :class="{ 'chips-container-mobile': $vuetify.breakpoint.smAndDown }"
                  :show-arrows="true"
                  v-model="selectedTag"
                  hide-details
                  v-click-outside="clickOutside"
                  :mandatory="selectedTag ? true : false"
                >
                  <v-chip
                    v-for="(tag, index) in tags"
                    :key="index"
                    small
                    outlined
                    :value="tag.uuid"
                  >
                    <span v-if="!inputEdit || selectedTag != tag.uuid">{{ tag.tag }}</span>
                    <VBtn
                      @click="
                        editedTag = { ...tag };
                        inputEdit = true;
                      "
                      v-if="selectedTag == tag.uuid && !inputEdit"
                      class="ml-1"
                      x-small
                      icon
                    >
                      <VIcon small>mdi-pencil</VIcon>
                    </VBtn>
                    <VBtn
                      @click="
                        deletedTag = tag.uuid;
                        confirmDelete = true;
                      "
                      v-if="selectedTag == tag.uuid && !inputEdit"
                      x-small
                      icon
                    >
                      <VIcon>mdi-close</VIcon>
                    </VBtn>
                    <v-text-field
                      class="py-0"
                      autocomplete="new-password"
                      append-icon="mdi-close"
                      @click:append="
                        editedTag = null;
                        inputEdit = false;
                      "
                      @keypress="validInput"
                      autofocus
                      v-if="inputEdit && selectedTag == tag.uuid"
                      style="width: 120px;"
                      @keydown.enter="updateTag"
                      dense
                      v-model="editedTag.tag"
                      hide-details
                      solo
                      :maxLength="15"
                    >
                    </v-text-field>
                  </v-chip>
                </v-chip-group>
              </div>
              <div>
                <v-tooltip bottom v-if="!addTagProccess">
                  <template v-slot:activator="{ on, attrs }">
                    <VBtn
                      v-on="on"
                      v-bind="attrs"
                      @focusin="
                        selectedTag = null;
                        addTagProccess = true;
                      "
                      icon
                      color="success"
                      small
                    >
                      <VIcon color="success" size="20">mdi-plus-circle-outline </VIcon>
                    </VBtn>
                  </template>
                  {{ $t("crm.add_tag") }}
                </v-tooltip>
                <v-combobox
                  class="ml-4 py-0"
                  autocomplete="new-password"
                  color="success"
                  append-icon="mdi-close"
                  :items="tagsFullList"
                  @click:append="
                    newTag = '';
                    addTagProccess = false;
                  "
                  @change="createTag"
                  @keypress="validInput"
                  autofocus
                  v-if="addTagProccess"
                  style="width: 120px;"
                  dense
                  v-model="newTag"
                  hide-details
                  :maxLength="15"
                  solo
                >
                </v-combobox>
              </div>
            </div>
          </v-card-subtitle>
        </div>
      </v-col>
      <v-col cols="auto" v-if="typeDialog !== 'add'" class="mx-auto ml-md-auto mx-md-0">
        <div
          class="v-card__subtitle py-0"
          v-if="item.uuid_user_assigned_to != '00000000-0000-0000-0000-000000000000'"
        >
          {{ $t("orderDialog.assigned_to") }}
          <span class="font-weight-bold">
            {{ item?.user_assigned_to_details?.name }}
            {{ item?.user_assigned_to_details?.surname }}
          </span>
        </div>
        <VBtn
          v-else
          @click="$emit('assignUpdate')"
          text
          color="success"
          class="text-transform-none"
        >
          <VIcon class="mr-1">mdi-play-circle</VIcon>{{ $t("orderDialog.start") }}
        </VBtn>
      </v-col>
      <div class="d-flex align-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                v-on="on"
                class="btn-edit mr-2"
                :color="item.is_call_required === '1' ? 'primary' : 'secondary lighten-5'"
                @click="$emit('callRequiredUpdate')"
                :disabled="item.type === 'out' || !inputDelivery.phone || !activeProduct"
              >
                <VIcon>
                  {{ item.is_call_required === "1" ? "mdi-phone" : "mdi-phone-off" }}
                </VIcon>
              </VBtn>
            </template>
            <span>
              {{ getHintText }}
            </span>
          </v-tooltip>

        <v-col
          cols="auto"
          md="auto"
          class="crmDialogItem__userState"
          v-if="list_states.length && activeProduct"
        >
          <VSelect
            :items="list_states"
            item-text="name"
            item-value="uuid"
            :placeholder="$t('form.choose')"
            v-model="state"
            :hide-details="true"
            @change="setState"
            dense
            outlined
            color="success"
            item-color="success"
          >
            <template slot="item" slot-scope="{ item }">
              <div class="user-state">
                {{ item?.name }}
                <span :style="`background-color: ${item.color};`"></span>
              </div>
            </template>
            <template slot="selection" slot-scope="{ item }">
              <div class="user-state">
                {{ item?.name }}
                <span :style="`background-color: ${item.color};`"></span>
              </div>
            </template>
          </VSelect>
        </v-col>
      </div>
    </v-row>
    <confirm-dialog
      :visible="confirmDelete"
      @close="confirmDelete = false"
      @onConfirm="deleteConfirm"
      @onCancel="confirmDelete = false"
      :typeAlert="'error'"
    >
      <template v-slot:header>
        {{ $t("confirmDialog.deleteTag.title") }}
      </template>
      <template v-slot:description>
        {{ $t("confirmDialog.deleteTag.desc") }}
      </template>
    </confirm-dialog>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import crmService from "@/services/request/crm/crmService";
import ConfirmDialog from "@/components/dialog/ConfirmDialog.vue";
import EventBus from "@/events/EventBus";

export default {
  components: {
    ConfirmDialog
  },
  name: "headerDialogCrm",
  data: () => ({
    state: "",
    isCallRequired: false,
    tags: [],
    tagsFullList: [],
    selectedTag: null,
    newTag: "",
    addTagProccess: false,
    loadingTags: false,
    confirmDelete: false,
    deletedTag: null,
    inputEdit: false,
    editedTag: null
  }),
  async mounted() {
    this.fetchData();
    this.item.is_call_required = "0";
    EventBus.$on("header-crm-assigned-user", props => {
      if (props.uuid_user != "00000000-0000-0000-0000-000000000000") {
        this.item.user_assigned_to_details.name = props.user_details.name;
        this.item.user_assigned_to_details.surname = props.user_details.surname;
        this.item.uuid_user_assigned_to = props.uuid_user;
      } else {
        this.item.uuid_user_assigned_to = "00000000-0000-0000-0000-000000000000";
      }
    });
  },
  computed: {
    getHintText() {
      if (this.item.is_call_required === "1" && this.inputDelivery.phone) {
        return this.$t("crm.call_center_required");
      } else if (this.item.is_call_required === "0" && this.inputDelivery.phone) {
        return this.$t("crm.call_center_not_required");
      } else {
        return this.$t("sms.errors.phone");
      }
    }
  },
  methods: {
    async copyToClipboard(e) {
      try {
        await navigator.clipboard.writeText(e);
        this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
      } catch (e) {
        console.log(e);
      }
    },
    async getTagsList(fullList = false) {
      this.loadingTags = true;
      try {
        const params = {};

        if (!fullList) {
          params.uuid_object = this.item.uuid;
        }
        const response = await crmService.getTagsList(params);

        if (!fullList) {
          this.tags = response;
        } else {
          this.tagsFullList = response.map(object => object.tag);
        }
        this.loadingTags = false;
      } catch (e) {
        console.log(e);
        this.loadingTags = false;
      }
    },
    validInput(evt) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode == 59) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    createAllTags() {
      try {
        if (this.tags.length) {
          this.tags.forEach(tag => {
            const formData = new FormData();
            formData.append("uuid_object", this.item.uuid);
            formData.append("tag", tag.tag);
            crmService.createTag(formData);
          });
        }
        setTimeout(() => {
          this.getTagsList();
        }, 500);
      } catch (e) {
        this.setErrorNotification(e);
      }
    },
    addTagToArray() {
      if (this.tags.findIndex(el => el.tag == this.newTag) == -1) {
        const index = this.tags.length > 0 ? this.tags[this.tags.length - 1].uuid + 1 : 1;
        this.tags.push({
          uuid: index,
          tag: this.newTag
        });
      } else {
        this.setErrorNotification(this.$t("crm.tag_already_exists"));
      }
      this.newTag = "";
      this.addTagProccess = false;
    },
    async createTag() {
      if (this.newTag) {
        try {
          this.loadingTags = true;
          this.addTagProccess = false;
          const formData = new FormData();
          formData.append("uuid_object", this.item.uuid);
          formData.append("tag", this.newTag);
          await crmService.createTag(formData);

          EventBus.$emit("crm-items-reset");
          this.newTag = "";
          this.getTagsList();
        } catch (e) {
          this.loadingTags = false;
          if (e.error.description.toLowerCase().includes("already exist")) {
            this.setErrorNotification(this.$t("crm.tag_already_exists"));
          } else {
            this.setErrorNotification(e);
          }
        }
      }
      this.addTagProccess = false;
    },
    deleteConfirm() {
      if (this.typeDialog === "add") {
        this.deleteTagFromArray();
      } else {
        this.deleteTag();
      }
    },
    deleteTagFromArray() {
      const objWithIdIndex = this.tags.findIndex(obj => obj.uuid === this.deletedTag);

      if (objWithIdIndex > -1) {
        this.tags.splice(objWithIdIndex, 1);
      }
      this.confirmDelete = false;
      this.deletedTag = null;
    },
    async deleteTag() {
      try {
        this.loadingTags = true;
        await crmService.deleteTag(this.deletedTag);

        this.confirmDelete = false;
        EventBus.$emit("crm-items-reset");
        this.deletedTag = null;
        this.getTagsList();
      } catch (e) {
        this.loadingTags = false;
        this.confirmDelete = false;
        this.deletedTag = null;
        console.log(e);
      }
    },
    async updateTag() {
      if (this.editedTag) {
        try {
          this.loadingTags = true;
          const formData = new FormData();
          formData.append("uuid", this.editedTag.uuid);
          formData.append("tag", this.editedTag.tag);
          await crmService.updateTag(formData);

          EventBus.$emit("crm-items-reset");
          this.editedTag = null;
          this.inputEdit = false;
          this.getTagsList();
        } catch (e) {
          this.loadingTags = false;
          if (e.error.description.toLowerCase().includes("already exist")) {
            this.setErrorNotification(this.$t("crm.tag_already_exists"));
          } else {
            this.setErrorNotification(e);
          }
        }
      }
      this.addTagProccess = false;
    },
    updateTagFromArray() {
      if (this.editedTag) {
        const index = this.tags.findIndex(el => el.uuid == this.editedTag.uuid);

        if (index > -1) {
          this.tags[index].tag = this.editedTag.tag;
        }

        this.editedTag = null;
        this.inputEdit = false;
      }
    },
    fetchData() {
      this.state = this.uuid_state;
    },
    setState() {
      this.$emit("change", this.state);
      if (this.clickOutside) {
        setTimeout(() => {
          EventBus.$emit("crm-items-reset");
        }, 400);
      }
    },
    clickOutside() {
      this.selectedTag = null;
      this.inputEdit = false;
      this.editedTag = null;
    }
  },
  props: {
    uuid_state: {
      type: String,
      require: true
    },
    item: {
      require: true
    },
    inputDelivery: {
      require: true
    },
    typeDialog: {
      require: true
    },
    loading: {
      require: true
    },
    activeProduct: {
      require: true
    },
    list_states: {
      type: Array,
      require: true
    }
  },
  mixins: [notifications],
  watch: {
    uuid_state: "fetchData",
    item: {
      handler() {
        if (this.item) {
          this.getTagsList();
        }
      }
    },
    selectedTag: {
      handler() {
        this.inputEdit = false;
      }
    },
    addTagProccess: {
      handler() {
        if (this.addTagProccess && !this.tagsFullList.length) {
          this.getTagsList(true);
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
.user-state {
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #eeeeee;
  }
}
.chips-container {
  max-width: 280px;
  &-mobile {
    max-width: 200px;
  }
}
</style>
