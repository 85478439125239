// eslint-disable-next-line import/no-cycle
import axios from "axios";
// eslint-disable-next-line import/no-cycle
import store from "@/store";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import * as actionTypes from "@/store/modules/notifications/types/actions";
import * as actionTypesAuth from "@/store/modules/auth/types/actions";

const API_ENDPOINT = process.env.VUE_APP_API_URL;
const TOKEN_NAME = "app_access_token";

function getToken() {
  return localStorage.getItem(TOKEN_NAME);
}

axios.interceptors.request.use(
  config => {
    if (getToken()) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${getToken()}`;
    }
    return config;
  },
  error => Promise.reject(error)
);

axios.interceptors.response.use(
  success => success,
  error => {
    if (error.response?.data?.statusCode === 401) {
      window.stop()
      localStorage.removeItem(TOKEN_NAME);
      store.state.auth.user = null;
      store.state.loader.isLoading = false;
      router.push({ name: "login" });
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject(error?.response?.data);
  }
);

const $t = label => store.getters.translation[label];

export default {
  patch(url, params = {}, headers = {}, errorNotify = true) {
    return axios
      .patch(API_ENDPOINT + url, {
        params,
        headers
      })
      .catch(error => {
        if (errorNotify) {
           
          this.notificationStatusCode(error);
        } else {
          return Promise.reject(error);
        }
      });
  },
  get(url, params = {}, headers = {}, errorNotify = true) {
    return axios
      .get(API_ENDPOINT + url, {
        params,
        headers
      })
      .catch(error => {
        if (errorNotify) {
          this.notificationStatusCode(error);
        } else {
          return Promise.reject(error);
        }
      });
  },
  post(url, body = {}, config = {}, errorNotify = true) {
    return axios
      .post(API_ENDPOINT + url, body,{header:config})
      .catch(error => {
        if (errorNotify) {
          this.notificationStatusCode(error);
        } else {
          return Promise.reject(error);
        }
      });
  },
  delete(url, config = {}, errorNotify = true) {
    return axios.delete(API_ENDPOINT + url, config).catch(error => {
      if (errorNotify) {
        this.notificationStatusCode(error);
      } else {
        return Promise.reject(error);
      }
    });
  },
  put(url, body = {}, config = {}, errorNotify = true) {
    return axios.put(API_ENDPOINT + url, body, config).catch(error => {
      if (errorNotify) {
        this.notificationStatusCode(error);
      } else {
        return Promise.reject(error);
      }
    });
  },
  postExternal(url, body = {}, config = {}, errorNotify = true) {
    return axios.post(url, body, config).catch(error => {
      if (errorNotify) {
        this.notificationStatusCode(error);
      } else {
        return Promise.reject(error);
      }
    });
  },
  postCustom(url, body = {}, errorNotify = true) {
    axios.defaults.withCredentials = false;
    return axios
      .post(url, body, {
        transformRequest: (data, headers) => {
          delete headers.Authorization;
          return data;
        }
      })
      .catch(error => {
       if(error.status.startsWith('5')){
        store.dispatch(`auth/${actionTypesAuth.SHOW_500_ERROR_DIALOG}`,true,error);

        return
       }
        if (errorNotify) {
          this.notificationStatusCode(error);
        } else {
          return Promise.reject(error);
        }
      });
  },

  getCustom(url, params = {}, errorNotify = true) {
    axios.defaults.withCredentials = false;
    return axios
      .get(url, {
        transformRequest: (data, headers) => {
          delete headers.Authorization;
          return data;
        }
      })
      .catch(error => {
        if (errorNotify) {
          this.notificationStatusCode(error);
        } else {
          return Promise.reject(error);
        }
      });
  },
  async notificationStatusCode(error) {
    if (error) {
      const testError = $t("error");
      const textError =
        error.statusCode >= 500 ? testError[`e${500}`] : testError[`e${error.statusCode}`];

      store.dispatch(`notifications/${actionTypes.SET_ERROR_NOTIFICATION}`, textError);
    }
  }
};
