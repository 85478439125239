<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.49996 8.00002H12.5C12.78 8.00002 13 7.78002
    13 7.50002V5.00002H14.79C15.24 5.00002 15.46 4.46002 15.14
    4.15002L11.35 0.360022C11.15 0.160022 10.84 0.160022 10.64
    0.360022L6.84996 4.15002C6.53996 4.46002 6.75996 5.00002 7.20996
    5.00002H8.99996V7.50002C8.99996 7.78002 9.21996 8.00002 9.49996
    8.00002ZM7.49996 9.00002H4.99996V7.21002C4.99996 6.76002 4.45996
     6.54002 4.14996 6.86002L0.359961 10.65C0.159961 10.85 0.159961 11.16
      0.359961 11.36L4.14996 15.15C4.45996 15.46 4.99996 15.24 4.99996
      14.79V13H7.49996C7.77996 13 7.99996 12.78 7.99996 12.5V9.50002C7.99996
       9.22002 7.77996 9.00002 7.49996 9.00002ZM21.65 10.65L17.86 6.86002C17.54
       6.54002 17 6.76002 17 7.21002V9.00002H14.5C14.22 9.00002 14 9.22002 14
       9.50002V12.5C14 12.78 14.22 13 14.5 13H17V14.79C17 15.24 17.54 15.46 17.85
        15.14L21.64 11.35C21.84 11.16 21.84 10.84 21.65 10.65ZM12.5 14H9.49996C9.21996
         14 8.99996 14.22 8.99996 14.5V17H7.20996C6.75996 17 6.53996 17.54 6.85996 17.85L10.65
         21.64C10.85 21.84 11.16 21.84 11.36 21.64L15.15 17.85C15.46 17.54 15.24 17
         14.8 17H13V14.5C13
          14.22 12.78 14 12.5 14Z"
    />
  </svg>
</template>

<script>
import iconMixin from './iconMixin';

export default {
  mixins: [iconMixin],
  name: 'MoveAll',
};
</script>

<style scoped>

</style>
