import * as actionTypes from "./types/actions";
import * as mutationTypes from "./types/mutations";

export default {
  [actionTypes.SET_START_TIME]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_START_TIME, value);
  },
  [actionTypes.SET_PAGE_LOAD_TIME]: ({ commit }, value = true) => {
    commit(mutationTypes.SET_PAGE_LOAD_TIME, value);
  }
};
