<template>
  <VNavigationDrawer
    fixed
    v-model="miniMob"
    absolute
    :style="{ top: '56px', height: `calc(100vh - 56px)`, width: mini ? '237.5px' : '57px', 'z-index':100}"
    :class="{ fixed: fixed }"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :mini-variant="!mini"
    :border="true"
  >
    <v-divider />
    <VList nav dense >
      <VListItemGroup  active-class="active-green--text" color="green">
        <div v-for="(list, key) in listData" :key="key">
          <div v-if="'nested_data' in list">
            <VListGroup
            color="green"
              class="listGroupMenu"
              v-if="list.permission"
              :value="list.value"
              active-class="active-green--text"
              @click="navigateTo(list.navigate_name)"
            >
              <template v-slot:activator>
                <VListItemIcon>
                  <VIcon>{{ list.activator_data.icon }}</VIcon>
                </VListItemIcon>

                <VListItemTitle>{{ list.activator_data.title }}</VListItemTitle>
              </template>

              <div v-for="(nested_data, key) in list.nested_data" :key="key">
                <VListItem
                active-class="active-green--text"
                  :to="$localize({ name: nested_data.navigate_name })"
                  v-if="nested_data.permission"
                >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <VListItemIcon v-bind="attrs" v-on="on"
                        ><VIcon>{{ nested_data.icon }}</VIcon></VListItemIcon
                      >
                    </template>
                    {{ nested_data.title }}
                  </v-tooltip>
                  <VListItemTitle>{{ nested_data.title }}</VListItemTitle>
                </VListItem>
              </div>
            </VListGroup>
          </div>
          <div v-else>
            <VListItem
              :active-class="$vuetify.theme.dark ? 'active-green--textBlack' : 'active-green--text'"
              :class="list.class"
              :to="$localize({ name: list.navigate_name, params: list.navigate_params })"
              v-if="list.permission"
              exact
            >
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <VListItemIcon v-bind="attrs" v-on="on"
                    >
                    <ns-badge v-if="list.badge && list.icon && list.navigate_name != 'contacts'" overlap :ns-content="list.badge" :ns-color="list.badgeColor" ns-size="xs">
                      <VIcon >{{ list.icon }}</VIcon>
                    </ns-badge>
                    <ns-badge v-if="list.icon && list.navigate_name == 'contacts' && amount != '0'" overlap :ns-content="amount" ns-color="orange darken-4" ns-size="xs">
                      <VIcon >{{ list.icon }}</VIcon>
                    </ns-badge>
                    <VIcon v-if="list.icon && !list.badge && list.navigate_name == 'contacts' && amount == '0'"  >{{ list.icon }}</VIcon>
                    <VIcon v-if="list.icon && !list.badge && list.navigate_name != 'contacts'"  >{{ list.icon }}</VIcon>
                    <package-out-icon :fill="iconColor" v-else-if="list.navigate_name === 'supply'" />
                    <move-all-icon :fill="iconColor"  v-else-if="list.navigate_name === 'move-list'" />
                    <payment-plans-icon :fill="iconColor"  v-else-if="list.navigate_name === 'plans-list'" />
                  </VListItemIcon>
                </template>
                {{ list.title }}
              </v-tooltip>
              <VListItemTitle>{{ list.title }}</VListItemTitle>
            </VListItem>
          </div>
        </div>

      </VListItemGroup>
    </VList>
    <span @dblclick="CopyToClipboard" v-if="mini" class="version-label">{{version}}({{getFormattedDate(date)}})</span>
    <span v-if="mini" class="load-mini-label">{{'Load time: '}} {{`${Math.round(pageLoadTime)} ms` }}</span>
    <span v-if="!mini" class="load-label">{{`${Math.round(pageLoadTime)} ms` }}</span>
  </VNavigationDrawer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import * as getterType from "@/store/modules/pageLoadTime/types/getters";
import lang from "@/mixins/language";
import user from "../../mixins/user";
import * as mutations from "@/store/modules/auth/types/mutations";
import * as actionTypes from "@/store/modules/auth/types/actions";
import navigationDraver from './navigationDrawer'
import newRequests from '@/mixins/newRequestsMixin'
import authService from "@/services/request/auth/authService";
import notifications from '@/mixins/notifications';
import EventBus from "@/events/EventBus";

export default {
  name: "LeftMenu",
  data: () => ({
    fixed: false,
    mini: false,
    miniMob: false,
    usersSelector: false,
    depotsSelector: false,
    userSettings: false,
    docsSelector: false,
    listData: [],
    docsList: [],
    version: '',
    date: '',
  }),
  methods: {
    getFormattedDate(date) {
      const formattedDate = new Date(date);
      const day = formattedDate.getDate();
      const month = formattedDate.toLocaleString('en-gb', { month: 'short' });
      return `${day} ${month}`;
    },
    CopyToClipboard() {
      const data = `${this.version} (${this.getFormattedDate(this.date)})`;
      navigator.clipboard.writeText(data);
      this.setSuccessNotification(this.$t("profile.copied_to_clipboard"));
    },
    ...mapActions("auth", {
      fetchUserData: actionTypes.FETCH_USER_DATA
    }),
    navigateTo(routeName) {
      if (!routeName) return;
      this.$router.push({ name: routeName }).catch(() => {});
    },
    async getVersion() {
      try {
        const res = await authService.getVersion()
        if (res.message.optimization) {
          this.version = 'V' + res.message.version
        }
        else {
          this.version = 'v' + res.message.version
        }
        this.date = res.message.date
      }
      catch(e) {
        console.log(e)
      }
    },
    fetchData() {
      if (!this.$vuetify.breakpoint.mdAndUp) {
        this.mini = true;
        this.miniMob = this.menuPosition;
      } else {
        this.mini = this.menuPosition;
        this.miniMob = false;
      }
      //
      //
    }
  },
  async mounted() {
    this.getVersion();
    await this.fetchData();

    if (this.permissions.can_view_join_request) {
      await this.fetchAmount()
    }
    EventBus.$on("balance-added-rerender", async item => {
      this.renderMenu();
    });
  },
  computed: {
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION
    }),
    ...mapGetters('pageLoadTime', {
      pageLoadTime: getterType.GET_PAGE_LOAD_TIME,
    }),
    ...mapGetters(["currentLanguage"]),
    iconColor() {
      return this.$vuetify.theme.dark ? "#FFFFFF" : "#757575";
    },
  },
  watch: {
    currentLanguage: {
      handler() {
        this.renderMenu()
      }
    },
    miniMob(e) {
      this.$store.commit(`auth/${mutations.SET_MENU_POSITION}`, e);
    },
    menuPosition: "fetchData"
  },
  mixins: [lang, user,navigationDraver, newRequests, notifications]
};
</script>
<style lang="scss">
.version-label {
  position: absolute;
  bottom: 0px;
  left: 10px;
  font-size: 8px;
  color: #aeaeae;
}
.load-mini-label {
  position: absolute;
  bottom: 0px;
  right: 10px;
  font-size: 8px;
  color: #aeaeae;
}
.load-label {
  position: absolute;
  bottom: 0px;
  left: 10px;
  font-size: 8px;
  color: #aeaeae;
}
.v-list-item--active{
  // background-color: #30e90b38 !important;

}
</style>
